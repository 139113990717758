.product-cart{
  padding-bottom: 63px;
}
.share{
  &-wrap{
    position: relative;
  }
  &-btn{
    position: absolute;
    top: 4px;

    right: -6px;
    border-radius: 50%;
    overflow: hidden;
    z-index: 1;
    svg{
      fill: #f1f1f1;
      transition: all .3s;
    }
    &:hover{
      svg{
        fill: color('grey', 2);
      }
    }
  }
  &-menu{
    background-color: color('black', 2);
    width: 153px;
    position: absolute;
    top: 4px;
    right: 0;
    display: none;
    padding: 17px 16px 7px;
    z-index: 1;
    &__item{
      color: #fefefe;
      @include font-size(10);
      letter-spacing: 3px;
      line-height: 1.4;
      text-transform: uppercase;
      position: relative;
      padding-left: 24px;
      margin-bottom: 18px;
      svg{
        position: absolute;
        fill: #fefefe;
        bottom: 0;
        left: 0;
      }
      a:hover{
        border-bottom: 1px solid;
      }
    }
    &__title{
      color: #fefefe;
      @include font-size(10);
      font-weight: 700;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 26px;
    }
    &__close{
      top: 10%;
      right: 17px;
      transition: opacity .3s;
      &:hover{
        opacity: .6;
      }
    }
  }
}

.product-pic{
  position: relative;
  &__item{
    outline: none;
  }
  &__img{
    margin: 0 auto;
    max-width: 510px;
    width: 100%;

  }
  &-wrap{
    margin-top: 77px;
    align-self: flex-start;
    padding: 0 12px;
    .slick-dots{
      justify-content: center;
      margin-top: 48px;
      align-self: flex-end;
      li{
        border: 1px solid color('black', 1);
        &:not(:last-child){
        }
        &.slick-active{
          background-color: color('black', 1);
        }
      }
    }
    .slick-arrow{
      display: none;
    }
    .arrows{
      height: 14px;
    }
    .slick-prev{
      left: 0;
      transform: rotateY(180deg);
    }
    .slick-next{
      right: 0;
    }
  }
}

.cart-info{
  padding-top: 42px;
  position: relative;
  &__title{
    margin: 2px 0 17px;
  }
  &__field{
    display: flex;
    flex-direction: column;
    padding: 16px 0;
  }
  &__left-stock{
    @include font-size(12);
    letter-spacing: 3px;
    line-height: 1.33;
    text-transform: uppercase;
    background-color: #f1f1f1;
    padding: 16px 0;

    text-align: center;
    max-width: 181px;
    width: 100%;
  }
  &__category{
    @include font-size(10);
    letter-spacing: 2.5px;
    text-transform: uppercase;
  }
  &__price{
    font-size: 20px;
    white-space: nowrap;
    margin-bottom: 32px;
    @extend .t_h2;
  }
  &__text{
    @extend .text-libre;
    line-height: 1.72;

  }
  .header__title{
    text-align: left;
    margin: 0 30px 26px 0;
    @include font-size(14);
    letter-spacing: 3px;
    line-height: 1.58;
    padding: 4px 0 10px;
  }
}

.volume{
  max-width: 325px;
  &__list{
    margin: 23px 0 47px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: hidden;
  }
  &__item{
    width: 136px;
    float: left;
    &:not(:last-child){
      margin-right: 16px;
    }
  }
  &__btn{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 59px;
    width: 100%;
  }
  &__inp{
    display: none;
    &:checked + .volume__btn{
      border: 1px solid color('black', 1);
    }
  }
  &__sub{
    &-wrap{
      max-width: 318px;
      width: 100%;
    }
    width: 100%;
    margin: 23px 0 64px;
    @include font-size(14);
    padding: 21px 0;
  }
}

.count-product{
  border-top: 1px solid color('grey', 2);
  border-bottom: 1px solid color('grey', 2);
  display: flex;
  flex: 0 1 50%;
  text-align: center;

  &__item{
    display: flex;
    justify-content: center;
    width: 50%;
    padding: 16px;
    align-items: center;
    @include font-size(16);
    letter-spacing: 4px;
    text-transform: uppercase;
    &:not(:last-child){
      border-right: 1px solid color('grey', 2);
    }
  }
  &__number{
    height: 32px;
    width: 32px;
    display: inline-block;
    background-color: #f1f1f1;
    border-radius: 50%;
    text-align: center;
    line-height: 2;
    padding: 0;
    cursor: default;
    letter-spacing: normal;
  }
  &__less{
    margin-right: 8px;
  }
  &__more{
    margin-left: 12px;
  }
  &__less,
  &__more{
    height: 24px;
    width: 24px;
    position: relative;
    &:before,
    &:after{
      content: '';
      @extend .u_overlay;
      height: 2px;
      width: 12px;
      background-color: color('black', 1);
      margin: auto;
    }
  }
  &__more:after{
    transform: rotate(90deg);
  }
}

.product-option{

  &__item{
    border-bottom: 1px solid color('black', 1);
    @include font-size(14);
    letter-spacing: 4px;
    line-height: 1;
    text-transform: uppercase;
    &:first-child{
      border-top: 1px solid color('black', 1);
    }
  }
  &__link{
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-img{
      width: 32px;
      height: 32px;
    }
  }
}


@media (min-width: 767px) and (max-width: 992px){
  .single_variation_wrap{
    width: 100%;
  }
}
.wrap-option{
  display: none;
  &__list{
    padding: 4px 0 12px;
  }
  &__item{
    @extend .text-libre;
    text-transform: none;
    letter-spacing: normal;
    position: relative;
    margin-bottom: 18px;
    &:before{
      content: '';
      display: inline-block;
      height: 5px;
      width: 5px;
      background-color: #5d5d5d;
      border-radius: 50%;
      margin-right: 6px;
      vertical-align: middle;
    }
    &.disable{
      opacity: .3;
      &:before{
        display: none;
      }
    }
  }
  &__text{
    @extend .text-libre;
    max-width: 450px;
    text-transform: none;
    padding: 0 0 24px;
    line-height: 1.72;
    letter-spacing: normal;
  }
}

#fork{
  transform: translate(0, 1px);
}
#knife{
  transform: translate(11px, 0)
}
#fork,#knife{
  transition: .3s;
}
#forkKnife{
  &.close{
    #fork{
      transform: translate(17px, 1px) rotate(45deg);
      transition: all .3s;
    }
    #knife{
      transform: translate(-5px, 5px) rotate(-45deg);
      transition: all .3s;
      //transform: rotate(-45deg) !important;
    }
  }
}

@media (min-width: $sm) {
  .cart-info{
    padding-top: 64px;
    &__field{
      padding: 0;
      flex-direction: row;
      margin: 25px 0 31px;
    }
    &__left-stock{
      margin-left: 115px;
      padding: 16.5px 0;
    }
    &__text{
      line-height: 1.88;
      max-width: 550px;
    }
    .header__title{
      margin: 15px 0 18px;
      letter-spacing: 4px;
    }
    &__category{
      @include font-size(14);
      letter-spacing: 4px;
    }
    &__title{
      @include font-size(42);
      margin: 8px 0 23px;
      letter-spacing: 7px;
    }
    &__price{
      @include font-size(32);
      letter-spacing: 5px;
      margin-bottom: 0;
    }
  }
  .share{
    &-menu{
      top: 24px;
      right: -8px;
    }
    &-btn{
      right: -15px;
      top: 23px;
    }
  }
  .volume{
    display: flex;
    flex-wrap: wrap;
    max-width: none;
    justify-content: space-between;
    margin-bottom: 64px;
    &__list{
      margin: 23px 0 32px;
      width: 100%;
    }
    &__sub-wrap{
      max-width: 332px;
      flex: 0 1 49%;
    }
    &__sub{
      margin: 0;
      @include font-size(16);
      padding: 19px 0;
    }
    &__item{
      width: 164px;
      margin-bottom: 16px;
      &:not(:last-child){
        margin-right: 9px;
      }
    }
  }
  .product-option{
    &__link{
      padding: 16px 0 16px 16px;
    }
  }
  .wrap-option{
    &__list{
      padding: 6px 16px 9px;
    }
    &__item{
      margin-bottom: 22px;
    }
    &__text{
      padding: 0 15px 23px;
      line-height: 1.9;
      max-width: none;
    }
  }
  .product-pic-wrap{
    margin-top: 75px;
    .slick-arrow{
      display: flex;
      margin-top: -34px;
    }
    .slick-dots{
      margin-top: 59px;
    }
  }
  .count-product{
    align-self: flex-start;
    flex: 0 1 49%;
    &__item{
      padding: 15px 15px 16px;
    }
  }
  .product-cart{
    padding-bottom: 0;
  }
}
@media (min-width: $md) {
  /*.share{
    &-btn{}
    &-menu{}
  }*/
  .product-pic{
    padding: 46px 15px 0 80px;
    &-wrap{
      margin-top: 120px;
      padding: 0;
      .slick-arrow{
        display: flex;
        margin-top: -60px;
      }
      .slick-next{
        right: -41px;
      }
    }
    .slick-dots{
      margin-top: 81px;
    }
  }

  .volume{

    &__list{
      margin: 23px 0 16px;

    }
    &__item{
      letter-spacing: 1px;
      &:not(:last-child){
        margin-right: 16px;
      }
    }
    &__sub{
      padding: 19px 0;
      max-width: 318px;
      margin-top: 42px;
    }
    .count-product{
      max-width: 325px;
    }
    &__sub-wrap{
      flex: 0 1 auto;
    }
  }

  .count-product{
    flex: 0 1 auto;
    width: 100%;
  }

  .cart-info{
    padding: 48px 0 0 72px;

    &__title{
      margin: 10px 0 -3px;
    }

    .horizontal-line{
      margin: 8px 0 -8px;
    }

    &__field{
      margin: 25px 0 16px;
    }

    &__left-stock{
      margin-left: 65px;
    }

    .header__title{
      margin: 15px 0 10px;
    }
  }

}
