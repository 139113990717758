%svg-common {
	background: url("svg-sprite-294f5f19.svg") no-repeat;
}

.i-arrow-down {
	@extend %svg-common;
	background-position: 0 0;
	width: 24px;
	height: 24px;
}

.i-checked {
	@extend %svg-common;
	background-position: 0 100%;
	width: 11px;
	height: 8px;
}

