.payment{
  &__title{
    @extend %font-size-24;
    margin: 66px 0 12px;
  }
  .field-options{
    &__group{
      flex-direction: column;
    }
    &__text{
      max-width: none;
    }
  }
}
.credit-card{
  background-color: #f3f3f3;
  margin-top: 27px;
  padding: 24px 17px 0;
  position: relative;
  display: none;

  &:before{
    content: '';
    border-bottom: 10px solid #f3f3f3;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    position: absolute;
    bottom: 100%;
    left: 30px;
  }
  &__title{
    @extend %font-size-16;
  }
  &__number{
    margin: 25px 0 15px;
    width: 100%;
    max-width: 470px;
  }
}
.requisites{
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 470px;
  flex-wrap: wrap;
  &__month,
  &__year{
    max-width: 115px;
    margin-bottom: 16px;
    float: left;
    width: 50%;
  }
  &__year{
    margin: 0 0 16px 0;
  }
  &__cvc{
    max-width: 115px;
  }
  .selectric .button{
    right: 15px;
  }
}

@media (min-width: $sm){
  .payment{
    &__title{
      text-align: center;
      margin: 66px 0 28px;
    }
    .field-options{
      &__group{
        flex-direction: row;
      }
      &__text{
        max-width: 400px;
      }

    }
  }
  .credit-card{
    padding: 26px 35px 8px;
    &__number{
      margin: 25px 0 24px;
    }
  }
  .requisites{
    flex-wrap: nowrap;
    &__month,
    &__year{
      max-width: 135px;
      margin-bottom: 24px;
    }
    &__cvc{
      max-width: 119px;
      margin-bottom: 24px;
    }
    .customSelect{
      &:after{
        right: 16px;
      }
    }
  }
}
@media (min-width: $md){
  .credit-card{
    padding: 29px 24px 24px;
    &__number{
      width: calc( 50% - 12px);
      max-width: none;
      margin-top: 27px;
    }
  }
  .requisites{
    width: calc( 50% - 12px);
    max-width: none;
  }
  .payment{
    .field-options{
      &__text{
        max-width: 460px;
      }
    }
    .btn-black{
      letter-spacing: 5px;
    }
  }
}