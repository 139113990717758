.footer{
  background-color: #f3f3f3;
  padding: 24px 0;
  .row{
    flex-direction: column;
    align-items: center;
  }
  &__copy{
    margin: 22px 0 4px;
  }
  &__copy,
  &__conditions {
    @include font-size(10);
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 0 12px;
    order: 1;
  }
  &__conditions{
    color: color('grey', 1);
    transition: all .3s;
    &:hover{
      color: color('black', 1);
    }
  }
}
.social{
  display: flex;
  &__btn{
    width: 32px;
    height: 32px;
    border: 1px solid rgba(52, 53, 52, .1);
    border-radius: 50%;
    margin: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
    svg{
      fill: color('black', 1);
      transition: all .3s;
    }
    &:hover{
      background-color: color('black', 1);
      svg{
        fill: #fff;
      }
    }
  }
}
@media (min-width: $sm) { // 768
  .footer {
    &__copy {
      margin: 18px 0 10px;
    }
  }
}
@media (min-width: $md){ // 992
  .footer{
    padding: 32px 0;
    .row{
      flex-direction: row;
      justify-content: space-between;
    }
    &__copy{
      margin: 0;
    }
    &__copy,
    &__conditions{
      order: 0;
      @include font-size(12);
      letter-spacing: 3px;
    }
  }
  .social{
    margin-left: 82px;
  }
}