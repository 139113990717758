.u_overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

%u_bg--white { background-color: white; }
%u_bg--black { background-color: black; }

.title-upper{// .t_h2
	/*color: color('black', 1);
	@include font-size(32);
	font-weight: $weight-semibold;
	letter-spacing: 5px;
	text-transform: uppercase;*/
}

.img-center{
	opacity: 0;
	transition: opacity .3s;
}

@media screen and (min-width: 992px){
	.underline{
		span{
			position: relative;
			display: inline;
			&:after{
				content: '';
				position: absolute;
				top: calc( 100% + 4px );
				left: 0;
				width: 0;
				height: 1px;
				background-color: #343534;
				transition: width .6s;
				display: block;
			}
		}
	}
	.showcase{
		&:hover{
			span:after{
				width: 100%;
			}
		}
	}
}