.search-page{
  .search-stores{
    background: #f3f3f3;
  }
}

.nav-map{
  //display: flex;
  //flex-direction: column;
  //margin-bottom: 64px;
  padding: 35px 0 0;
  &__wrap{
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;
  }
  &__tabs{
    display: flex;
    justify-content: center;
    margin: 0 -8px 0px;
    &-item{
      color: #343534;
      @include font-size(12);
      font-weight: $weight-semibold;
      letter-spacing: 3px;
      text-transform: uppercase;
      border: 1px solid #f3f3f3;
      margin: 0 8px;
    }
    &-link{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 136px;
      height: 48px;
      outline: none;
      &.active{
        background: #f3f3f3;
      }
    }
  }
}
.navigation-map{
  width: 100%;
  padding-top: 16px;
  &__title{
    font-family: $openSans;
    line-height: 1.9;
    color: color('black', 1);
    @include font-size(28);
    letter-spacing: 6px;
    font-weight: $weight-semibold;
    text-transform: uppercase;
    padding: 0;
    position: relative;
    text-align: center;
    display: none;
    &:after{
      content: '';
      width: 64px;
      height: 1px;
      display: block;
      margin: 24px auto 32px;
      background-color: #dc3e38;
    }
  }
  &__list{
    margin-bottom: 17px;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }
  &__place{
    padding: 0 16px;
    transition: .3s;
    cursor: pointer;
    margin-bottom: -1px;
    flex: 0 1 100%;
    &:hover,
    &.selected{
      background-color: #f2f2f2;
      &+li{
        p{
          border-top-color: transparent;
        }
      }
      p{
        border-color: transparent;
      }
    }
    p{
      transition: .3s;
      font-family: $openSans;
      @include font-size(16);
      letter-spacing: 4px;
      color: color('black', 1);
      text-transform: uppercase;
      font-weight: 600;
      display: block;
      padding: 29px 0 38px;
      border-top: 1px solid #e3e3e5;
      span{
        margin-top: 2px;
        @include font-size(14);
      }
    }
    &:first-of-type{
      p{
        border-top: none;
      }
    }
  }
  &__more-result{
    @include font-size(14);
    padding: 0 20px 0 16px;
    //display: inline;
    display: flex;
    align-items: center;
    letter-spacing: 3.5px;
    width: 290px;
    margin: 42px auto 55px;
    color: #dc3e38;
    font-family: $openSans;
    font-weight: 600;
    line-height: 16px;
    text-transform: uppercase;
    position: relative;
    fill: none;
    stroke: color('red', 1);
    &-icon{
      position: relative;
      bottom: 2px;
      left: 10px;
      path{
        stroke: #dc3e38;
      }
    }
    &:hover{
      color: #343534;
      stroke: color('black', 1);
    }
  }
  span{
    display: block;
    opacity: 0.8;
    color: #343534;
    font-family: $libreBaskerville;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    letter-spacing: 0;
  }
}
.search-map{
  width: 100%;
  height: 380px;
  margin-top: 50px;
  overflow: hidden;
  padding: 0 16px;
}

.map-title_mob{
  @include font-size(24);
  letter-spacing: 3px;
  line-height: 1.9;
  color: color('black', 1);
  font-weight: $weight-semibold;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  &:after{
    content: '';
    width: 40px;
    height: 1px;
    display: block;
    margin: 20px auto 30px;
    background-color: #dc3e38;
  }
  span{
    display: block;
    opacity: 0.8;
    color: #343534;
    font-family: $libreBaskerville;
    @include font-size(14);
    font-weight: 400;
    line-height: 30px;
    text-transform: capitalize;
    letter-spacing: 0;
  }
}

@include mq(768){
  .nav-map{
    padding: 0;
    &__tabs{
      display: none;
    }
    &__wrap {
      margin-bottom: 0;
    }
  }
  .map-title_mob{
    display: none;
  }
  .navigation-map {
    padding-top: 45px;
    &__title {
      display: block;
    }
    &__list{
      padding: 0 28px;
    }
    &__place {
      flex: 0 1 50%;
      padding: 0 12px 0 12px;
      &:nth-child(1),
      &:nth-child(2){
        p{
          border-top: none;
        }
      }
      p{
        padding: 24px 25px 33px;
        @include font-size(18);
        line-height: 32px;
        span{
          @include font-size(16);
        }
      }
    }
    span{
      line-height: 30px;
    }
    &__more-result{
      padding: 0 5px;
      @include font-size(16);
      letter-spacing: 4px;
    }
  }
  .search-map{
    height: 500px;
    margin-top: 0;
    padding: 0;
  }
}

@include mq(1024){
  .nav-map{
    &__wrap{
      flex-direction: row;
      margin-bottom: 64px;
    }
  }
  .navigation-map{
    width: 500px;
    padding-top: 63px;
    &__title{
      @include font-size(32);
      text-align: left;
      padding: 0 24px 0 112px;
      letter-spacing: 5.5px;
      line-height: 1.6;
      &:after{
        margin: 24px 0 32px;
      }
    }
    &__list{
      display: block;
      padding: 0;
    }
    &__place{
      padding: 24px 24px 0 112px;
      p{
        border-bottom: 1px solid #e3e3e5;
        border-top: none;
        padding: 0 25px 33px 0;
      }
      &:last-of-type{
        p{
          border-bottom: none;
        }
      }
    }
    &__more-result {
      padding: 0 35px 0 112px;
      margin: 20px 0 0;
      width: auto;
    }
  }
  .search-map{
    width: calc(100% - 500px);
    height: 725px;
  }
}

@include mq(1200){
  .navigation-map {
    width: 628px;
  }
  .search-map {
    width: calc(100% - 628px);
  }
}
