.cart-page{
  padding-bottom: 36px;
  &__top-title{
    @include font-size(30);
    margin: 30px 0 20px;
    letter-spacing: 9px;
    text-align: center;

    @media (min-width: 992px) {
      @include font-size(40);
      margin: 81px 0 50px;
    }
  }
  &__title{
    letter-spacing: .18em;
    text-align: center;
  }
  &__manage{
    margin: 20px 0 44px;
    letter-spacing: 0.015em;
    text-align: center;
  }

  .cart-price,
  .count-product{
    width: 100%;
    text-align: center;
    @media (min-width: 576px) {
      width: 33.3%;
    }
    @media (min-width: 992px) {
      width: 192px;
      text-align: left;
    }
  }

  .clear-cart{
    @include font-size(12);
    font-weight: 600;
    line-height: 1.33;
    text-transform: uppercase;
    letter-spacing: 3px;
    display: inline-block;
    margin: 36px auto;
    position: relative;
    transition: all .3s;
    &:after{
      content: '';
      background-color: #343534;
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      height: 1px;
      transition: all .3s;
    }
    &:hover{
      color: color('red', 1);
      &:after{
        background-color: color('red', 1);
      }
    }

    @media (min-width: 992px) {
      margin: 43px auto 16px;
    }
  }
  @media (min-width: 992px) {
    padding-bottom: 104px;
    .container{
      max-width: 1034px;
    }
  }
}
.cart{
  &-desc{
    width: 100%;
    margin-bottom: 24px;
    text-align: center;
    &__wrap-img{
      max-width: 119px;
      height: 119px;
      display: block;
      margin: 0 auto 16px;
      @media (min-width: 992px) {
        margin: 0 0 16px;
      }
    }
    &__img{
      width: 100%;
    }
    &__category,
    &__title{
      @include font-size(16);
      font-weight: 400;
      line-height: 1.5;
      text-transform: uppercase;
      letter-spacing: 4px;
      display: block;
    }
    &__gr{
      color: color('red', 1);
      @include font-size(12);
      line-height: 1.33;
      text-transform: uppercase;
      letter-spacing: 3px;
      margin-top: 6px;
    }

    @media (min-width: 992px) {
      width: 340px;
      text-align: left;
      margin-right: 9px;
      margin-bottom: 0;
    }
  }
  &-price{
    letter-spacing: 0.22em;
    span{
      margin-left: 11px;
    }
  }
}
.products{
  margin-bottom: 51px;
  &__top-field{
    background-color: color('black', 1);
    color: #fff;
    display: flex;
    padding: 25px 25px 24px;
  }
  &__field{
    padding: 24px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    border: 1px solid color('grey', 2);
    border-top: none;
  }
  &__item-name{
    @include font-size(16);
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: 4px;
    margin-right: 75px;
    display: none;
    &:first-child{
      width: 340px;
      margin-right: 9px;
      display: block;
    }

    @media (min-width: 992px) {
      display: block;
      margin-right: 0;
      width: 200px;
      &:last-child{
        margin-left: -20px;
      }
    }
  }
  .count-product{
    width: 100%;
    align-self: auto;
    border: none;
    flex: none;
    &__item{
      width: auto;
      padding: 0;
      margin: 16px auto;
      @media (min-width: 992px) {
        margin: 0;
      }
    }

    @media (min-width: 576px) {
      width: 33.3%;
      margin: auto;
    }
    @media (min-width: 992px) {
      width: 192px;
      margin: 0;
    }
  }
  &__btn-del{
    display: block;
    position: absolute;
    margin-top: -14px;
    height: 28px;
    width: 28px;
    top: 30px;
    right: 16px;
    cursor: pointer;
    &:before,
    &:after{
      content: '';
      height: 1px;
      width: 100%;
      margin: auto;
      @extend .u_overlay;
      background-color: color('black', 1);

    }
    &:before{
      transform: rotate(45deg);
    }
    &:after{
      transform: rotate(-45deg);
    }

    @media (min-width: 992px) {
      top: 50%;
      right: 30px;
    }
  }
}
.coupon{
  //background-color: #f3f3f3;
  padding: 0 24px;
  position: relative;
  border: 1px solid color('grey', 2);
  /*&:before{
    content: '';
    border-bottom: 10px solid #f3f3f3;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    position: absolute;
    bottom: 100%;
    left: 30px;
  }*/
  &__group{
    padding-bottom: 23px;
    display: none;
  }
  &-wrap{
    @media (min-width: 992px){
      padding-right: 18px;
    }
  }
  &__title{
    @include font-size(16);
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: 4px;
    padding: 24px 0;
    cursor: pointer;
    position: relative;
    &:before{
      content: '';
      display: block;
      position: absolute;
      right: 2px;
      transition: transform .3s;
      @extend .i-arrow-down;

    }
    &.active{
      &:before{
        transform: rotateX(180deg);
      }
    }
  }
  &__inp{
    border: 1px solid #e3e3e5;
    background-color: #fff;
    color: rgba(52, 53, 52, .5);
    @include font-size(16);
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    outline: none;
    height: 64px;
    margin-bottom: 33px;
    width: 100%;
    padding: 24px 24px 20px;
  }
  &__sub{
    @include font-size(12);
    display: block;
    padding: 20px 0;
    width: 100%;
    font-weight: $weight-bold;
    @media (min-width: 576px){
      width: auto;
      padding: 20px 30px;
    }
    @media (min-width: 767px){
      @include font-size(16);
      font-weight: $weight-regular;
    }
  }
}
.total-price{
  border: 1px solid color('grey', 2);
  &-wrap{
    @media (min-width: 992px){
      padding-left: 16px;
    }
  }
  &__item{
    display: flex;
    justify-content: space-between;
    padding: 23px 24px 25px;
    flex-wrap: wrap;
    &:not(:first-child){
      border-top: 1px solid color('grey', 2);
    }
    &.subtotal{
      padding: 32px 24px 23px;
    }
  }
  &__text,
  &__price{
    @include font-size(16);
    font-weight: 400;
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: 4px;
  }
  &__price{
    min-width: 109px;
    span{
      margin-left: 9px;
    }
  }
  &__to-checkout{
    display: block;
    padding: 7px 0 19px;
    margin: 31px auto 0;
    width: 100%;
    text-align: center;
    .svg-arrow{
      position: relative;
      top: 6px;
    }
    @media (min-width: 576px){
      width: auto;
      padding: 7px 31px 19px;
    }
    @media (min-width: 767px){
      padding: 14px 31px 18px;
    }
  }
}
.discount{
  width: 100%;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  margin-top: 10px;
  transition: opacity .3s;
  &.active{
    opacity: 1;
  }
  &__text,
  &__price{
    color: color('red', 1);
    @include font-size(12);
    line-height: 1.33;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
  &__price{
    min-width: 112px;
    span{
      margin-left: 9px;
    }
  }
}