//-----------------------------------------------------------
// BASE
//-----------------------------------------------------------
body {
	background-color: #fff;
	color: color('black', 1);
	font-family: $openSans;
	font-weight: $weight-semibold;
	overflow-x: hidden;
	overflow-y: auto !important;
	height: 100% !important;

	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: antialiased !important;
	font-smooth: always;
}
.over-hidden{
	overflow: hidden !important;
}


.wow {
	visibility: hidden;
}

@include mq(1025){
	.animate-mask{
		position: relative;
		&:after{
			content: '';
			display: block;
			z-index: 20;
			position: absolute;
			top: -1%;
			left: 0;
			width: 100%;
			height: 102%;
			background: #fefefe;
			transform-origin: top right;
			transform: scale(1, 1);
			transition-delay: 0s;
		}
		&.animated{
			&:after{
				transform: scale(0, 1);
				transition: -webkit-transform 0.9s cubic-bezier(0.84, -0.01, 0.48, 0.995);
				transition: transform 0.9s cubic-bezier(0.84, -0.01, 0.48, 0.995);
			}
		}
	}

	.animate-wrap{
		position: relative;
		color: transparent;
		overflow: hidden;
		display: inline-block;
		transition: color 0s linear 0.5s;
		&:after{
			content: '';
			display: block;
			z-index: 20;
			position: absolute;
			left: -100%;
			top: 0;
			width: 100%;
			height: 100%;
			background: color('black', 1);
			transform: scale(1, 1);
		}
		&.animated{
			color: color('black', 1);
			&:after{
				transform: translateX(100%);
				left: 10%;
				transition: left 0.5s cubic-bezier(0.84, -0.01, 0.48, 0.995), transform 0.5s cubic-bezier(0.84, -0.01, 0.48, 0.995) 0.5s;
			}
		}
	}
}
