/*.terms{
  .container{
    max-width: 1220px;
  }
}*/
.terms-wrap{
  padding: 37px 0 40px;

  &__main-title{
    margin-bottom: -21px;
  }
  &__title{
    margin: 56px 0 8px;
    line-height: 1.36;
    letter-spacing: 0.16em;
  }
  &__text{
    @extend .text-libre;
    line-height: 1.74;
    margin-bottom: 24px;

  }
}
@media (min-width: $sm) and ( max-width: $md){
  .terms{
    .container{
      padding: 0 40px;
      max-width: 100%;
    }
  }
}
//$md: 992px;
//$sm: 768px;
@media ( min-width: $sm){
  .terms-wrap {
    &__main-title{
      margin-bottom: 0;
    }
    padding: 42px 0 32px;
    &__title{
      margin: 65px 0 16px;
      line-height: 1;
      letter-spacing: 0.175em;
    }
    &__text{
      line-height: 1.88;
      margin-bottom: 30px;
    }
  }
}
@media ( min-width: $md){
  .terms-wrap {
    padding: 81px 0 72px;
    &__main-title{
      letter-spacing: 0.18em;
    }
    &__title{
      margin-top: 64px;
    }
  }
}
