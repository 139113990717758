.main-slider{
  padding: 47px 0 65px;
  overflow-x: hidden;
}
.text-slide{
  &__top-desc{
    width: 100%;
  }
  &__title{
    line-height: 1;
    margin-top: -5px;
  }
  &__text{
    @extend .text-libre;
    line-height: 1.72;
    margin-top: 14px;
    max-height: 95px;
    overflow: hidden;
    transition: max-height .3s;
    &.overhidd{
      max-height: 400px;
      transition: max-height .5s;
    }
  }
  .read{
    margin-top: 24px;
  }

  .slick-list{
    padding-top: 7px;
    margin-top: -7px;
  }
}
.read{
  color: #dc3e38;
  @include font-size(16);
  letter-spacing: 4px;
  text-transform: uppercase;
  transition: all .3s;
  svg{
    stroke: color('red', 1);
    transition: all .3s;
  }
  &.open{
    svg{
      transform: rotateX(180deg);
    }
  }
  /*&:hover{
    color: color('black', 1);
    svg{
      stroke: color('black', 1);
    }
  }*/
}
.img-slide,
.text-slide{
  width: 100%;
}
.product-pic-wrap,
.info-slider{
  width: 100%;
  position: relative;
  .slick-arrow{
    display: flex;
    display: -webkit-flex;
    position: absolute;
    top: 50%;
    height: 56px;
    width: 56px;
    background-color: #fff;
    color: transparent;
    overflow: hidden;
    z-index: 1;
    justify-content: center;
    transition: all .3s;
    &:hover{
      padding-left: 20px;
    }
  }

  .slick-dots{
    display: flex;
    font-size: 0;
    li{
      height: 8px;
      width: 8px;

      border-radius: 50%;
      overflow: hidden;
      transition: all .3s;
      cursor: pointer;
      &:not(:last-child){
        margin-right: 12px;
      }

    }
  }
  .arrows{
    width: 10px;
    position: relative;
    transition: all .3s;
    display: block;
    margin: auto;
    &:before,
    &:after{
      content: '';
      position: absolute;
      display: block;
      width: 1px;
      background-color: color('black', 1);
      transform-origin: 0 0;
      top: 50%;
      left: 100%;
      margin-top: -1px;
    }
    &:before{
      transform: rotate(45deg);
      height: 100%;
    }
    &:after{
      transform: rotate(135deg);
      height: 90%;
    }
  }
}

.info-slider{

  &__wrap{
    outline: none;
    display: flex !important;
    padding-top: 17px;
    position: relative;
  }
  &__left-wrap{
    display: none;

  }
  &__right-wrap{
    max-width: 100%;
    display: flex;
    flex: 0 1 auto;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  &__wrap-outer{
    position: relative;
  }

  &__img-outer{
    align-self: flex-end;
  }

  &__wrap-inner{
    width: 100%;
    height: 157px;
    position: relative;
    overflow: hidden;
  }

  &__img-inner{
    //width: 100%;
    //height: 157px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    max-width: 100%;
    margin: auto;
  }
  .slick-slide{
    outline: none;
    margin-right: 24px;
  }
  .slick-list{
    overflow: visible;
  }

  .slick-dots{
    margin: -16px 0 34px;
    align-self: center;
    z-index: 2;
    li{
      border: 1px solid #fff;
      &.slick-active{
        background-color: #fff;
      }
    }
  }
  .slick-arrow{
    bottom: 134px;
    top: auto;
    margin-top: 21px;
    display: none;
  }
  .arrows{
    height: 7px;
  }
  .slick-prev{
    left: -1px;
    transform: rotateY(180deg);
  }
  .slick-next{
    right: -1px;
  }
}

@media (min-width: 576px) { // 768
  .info-slider {
    &__wrap-inner {
      height: 264px;
    }
  }
}

@media (min-width: $sm) { // 768
  .main-slider{
    padding: 61px 0 63px;
  }
  .info-slider{
    .slick-arrow{
      display: flex;
      bottom: 136px;
    }
    &__right-wrap{
      flex-direction: row;
    }
    &__wrap-inner{
      height: 329px;
    }
    //&__img-inner{
    //
    //}
    .slick-slide{
      margin-right: 0;
    }

    &__left-img{
      height: auto;
    }

    .slick-list{
      overflow: hidden;
    }
    .slick-dots{
      align-self: flex-end;
      margin: 75px auto 32px;
      li{
        border: 1px solid color('black', 1);
        &.slick-active{
          background-color: color('black', 1);
        }
      }
    }
  }
  .text-slide{
    &__text{
      line-height: 1.88;
      margin-top: 25px;
      max-height: none !important;
    }

  }
  .read{
    display: none;
  }
}
@media (min-width: $md){ //992
  .main-slider{
    padding: 100px 0 103px;
    .container{
      max-width: 1044px;
    }
  }
  .info-slider{
    &__left-outer{
      width: 390px;
      height: 601px;
    }
    &__left-img{
      width: 100%;
    }
    &__left-wrap{
      display: block;
      max-width: 40%;
      flex: 0 1 40%;
      padding: 0 18px 0 0;
    }
    &__right-wrap{
      max-width: 60%;
      flex: 0 1 60%;
      padding: 0 0 0 18px;
      display: flex;
      flex-wrap: wrap;
    }
    &__wrap-inner{
      height: 282px;
    }
    //&__img-inner{
    //  height: 282px;
    //}
    .slick-dots{
      margin: 0;
    }
    .slick-arrow{
      top: 50%;
      bottom: auto
    }
    .text-slide{
      &__text{
        margin-top: 22px;
      }
    }
  }
}