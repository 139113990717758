.wrap-404{

}
.error-404{
  height: calc( 100vh - 217px);
  padding-top: 16px;
  .row{
    align-items: center;
    flex-direction: column-reverse;
  }
  &__title{
    @include font-size(16);
    letter-spacing: 4px;
    line-height: 1.67;
    text-transform: uppercase;
    text-align: center;
    margin: 16px 0;
  }
  &__btn{
    @include font-size(16);
    padding: 19px 0;
    max-width: 318px;
    width: 80%;
    display: block;
    text-align: center;
    margin: 0 auto;
  }

  &__img{
    width: 75%;
    max-width: 400px;
    margin: 0 auto;

  }
}

@media (min-width: $sm){
  .error-404{
    height: calc( 100vh - 213px);
    &__title{
      margin-top: 32px;
    }
    &__btn{
      width: 100%;
    }
  }
}

@media (min-width: $md){
  .error-404{
    height: calc( 100vh - 171px);

    &__img{
      max-width: 541px;
      margin: 0 auto 0 28px;
      width: 80%;
    }
    &__title{
      @include font-size(24);
      letter-spacing: 5px;
      margin: -6px 0 30px;
      padding-left: 7px;
    }
  }
}
@media (max-device-height: 612px) and (orientation : landscape) {
  .error-404{
    height: 100% !important;
    padding: 16px 0 32px;
  }
}
@media (min-width: $lg){
  .error-404{
    .row{
      flex-direction: row;
    }
    &__title{
      letter-spacing: 7.6px;
      text-align: left;
    }
    &__btn{
      margin-left: 36px;
    }
  }
}
@media (min-height: 640px) {
  .error-404{
    padding-top: 5vh;
  }
}
@media (min-height: 820px) {
  .error-404{
    padding-top: 92px;
  }
}
@media (min-height: 1200px) {
  .error-404{
    padding-top: 180px;
    &__title{
      margin-top: 48px;
    }
    &__img{
      margin: 0 auto;
    }
  }
}