.popular-product {
  padding: 49px 0 64px;
  text-align: center;
  border-top: 1px solid #e3e3e5;
  &__title{
    margin-bottom: 71px;
    position: relative;
    .horizontal-line{
      position: absolute;
      bottom: -25px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}

.showcase{
  display: flex;
  &:not(:last-child){
    margin-bottom: 54px;
  }
  &:nth-child(odd){
    flex-direction: row-reverse;
    .showcase__desc-wrap{
      text-align: left;
    }
  }
  &:hover{
    .showcase__link:after{
      width: 100%;
    }
  }

  &__desc-wrap{
    width: calc( 100% - 159px );
    z-index: 2;
    text-align: right;
  }
  &__link{
    position: relative;
    margin: 2px 0 14px;
    display: block;
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 1;
    @include font-size(24);
    span{
      display: inline;
    }
  }
  &__category{
    @include font-size(10);
    line-height: 1.4;
    letter-spacing: 2.5px;
  }
  &__wrap-img{
    height: 152px;
    width: 159px;
    margin: 0 auto 25px;
    a{
      display: block;
      height: 100%;
      width: 100%;
    }
  }
  &__btn{
    width: 107px;
    padding: 15px 0;
    display: inline-block;
    text-align: center;
  }
  &__option{
    color: color('red', 1);
    @include font-size(10);
    letter-spacing: 2.5px;
    text-transform: uppercase;
    margin-bottom: 22px;
  }
}

.show-more{
  max-width: 340px;
  width: 100%;
  padding: 17px 12px 18px;
  margin: 52px auto 0;
  display: inline-block;
  text-align: center;
  .svg-arrow{
    display: none;
  }

}

@media screen and (min-width: $sm) { // 768
  .popular-product {
    padding: 95px 0 40px;
    border-top: none;
    &__title{
      margin-bottom: 85px;
    }
  }

  .showcase{
    margin-bottom: 0;
    flex-direction: column !important;
    display: none;
    &:nth-child(-n+2){
      display: block;
      margin-bottom: 17px;
    }
    &__wrap-img{
      height: 247px;
      width: 231px;
      margin-bottom: 3px;
    }
    &__category{
      @include font-size(14);
      letter-spacing: 4px;
    }
    &__link{
      margin: 5px 0 33px;
      @include font-size(32);
      letter-spacing: 5px;

    }
    &__option{
      @include font-size(14);
      letter-spacing: 4px;
    }
    &__btn{
      width: 157px;
    }
    &__desc-wrap{
      text-align: center !important;
      width: auto;
    }
  }

  .show-more{
    margin: 48px 12px 25px;
    padding: 22px 12px 23px;
    .svg-arrow{
      vertical-align: sub;
      fill: color('black', 2);
      transition: all .3s;
      position: relative;
      top: 2px;
      display: inline-block;
    }
    &:hover{
      .svg-arrow{
        fill: #fefefe;
      }
    }
  }
}
@media screen and (min-width: $md) { // 992

  .popular-product{
    padding: 107px 0 81px;
    &__title{
      .horizontal-line{
        bottom: -18px;
      }
    }
  }

  .showcase{
    display: block;
    margin-bottom: 0 !important;
    &__wrap-img{
      height: 230px;
      max-width: 250px;
      margin-bottom: 22px;
    }
    &__link{
      margin: 7px 0 34px;
    }
  }

  .wrap-option{
    &__list{
      padding: 15px 16px 9px;

    }
    &__text{
      padding-top: 8px;
    }
  }
}