.success-page,
.error-page{
  text-align: center;
  height: calc( 100vh - 204px );
  &__main-title{
    @include font-size(28);
    letter-spacing: 3.5px;
    text-transform: uppercase;
  }
  &__title{
    @extend %font-size-24;
  }
  &__text{
    @extend .text-libre;
    max-width: 370px;
    line-height: 1.71;
  }
  &__mail{
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
  }
  &__btn{
    padding: 23px 0;
    display: block;
    max-width: 318px;
    margin: 0 auto;
  }
}

.success-page{
  &__main-title{
    margin: 54px 0 30px;
  }
  &__text{
    margin: 10px auto 33px;
  }
}
.error-page{
  &__main-title{
    margin: 62px 0 30px;
  }
  &__text{
    margin: 10px auto 31px;
  }
}

@media (min-width: $sm) {
  .success-page,
  .error-page{
    height: calc( 100vh - 210px );
    &__main-title{
      margin: 118px 0 50px;
      @include font-size(42);
      letter-spacing: 7px;
    }
    &__text{
      margin: 12px auto 40px;
      line-height: 1.88;
    }
    &__btn{
      padding: 19px 0;
    }
  }
}
@media (min-width: $md) {
  .success-page,
  .error-page{
    height: calc( 100vh - 170px );
    &__main-title{
      margin: 77px 0 43px;
    }
    &__text{
      margin: 12px auto 32px;
      max-width: 370px;
    }
  }
}