@keyframes menuItem {
  0%{
    transform: translateY(5000px);
    opacity: 0;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
}

.main-menu{
  position: relative;
  z-index: 51;
}
.menu{
  display: flex;
  justify-content: space-between;
  padding: 13px 16px 14px;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  align-items: center;
  background: #ffffff;
  width: 100%;
  &__cart{

    display: flex;
    align-items: center;
    &-text{
      color: color('grey', 1);
      @include font-size(10);
      line-height: 1.33;
      text-transform: uppercase;
      transition: color .3s;
      vertical-align: middle;

    }
    &-float{
      display: none;
    }
    .active,
    &:hover{
      .menu__cart-text{
        color: color('black', 1);
      }
    }
  }
}
#cart-ic{
  width: 24px;
  padding: 13px 0 0;
}
.burger{
  display: block;
  height: 24px;
  width: 24px;
  position: relative;
  margin-top: 4px;
  span{
    display: block;
    width: 24px;
    height: 1px;
    margin: auto;
    background-color: color('black', 2);
    transition: width .3s;
    position: absolute;
    right: 0;
    top: 50%;
    &:before,
    &:after{
      content: '';
      position: absolute;
      width: 24px;
      height: 1px;
      background-color: inherit;
      right: 0;
    }
    &:before{
      top: -8px;

    }
    &:after{
      top: 8px;
      transition: width .3s;
    }
  }
  &:hover{
    span{
      width: 12px;
      &:after{
        width: 18px;
      }
      &:after{
        width: 18px;
      }
    }
  }
}
.navigation{
  max-width: 165px;
  position: relative;
  &__btn{
    @include font-size(12);
    letter-spacing: .2em;
    text-transform: uppercase;
    position: relative;
    top: -3px;
    padding: 0 22px 0 0;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:after{
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: color('black', 1);
    }
    svg{
      transition: all .3s;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }
    &.active{
      svg{
        transform: rotateX(180deg) translateY(50%);
      }
    }
  }
  &__list{
    position: absolute;
    top: calc(100% + 5px);
    width: 100%;
    z-index: 2;
    padding: 14px 0;
    display: none;
    background-color: #fff;
    flex-direction: column;
  }
  &__item{
    @include font-size(12);
    letter-spacing: 3px;
    line-height: 0.83;
    text-transform: uppercase;
    color: color('black', 1);
    &:not(:last-child){
      margin-bottom: 9px;
    }
  }
  &__link{
    display: block;
    position: relative;
    padding: 8px;
    span{
      transition: all .15s cubic-bezier(.215,.61,.355,1);
    }

    &:hover,
    &.active{
      background-color: #f2f2f2;
    }
  }
  &__link-top{
    position: relative;
    display: block;
  }
}
.watch-menu{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  &.open{
    .sec-menu{
      transform: translateX(0);
    }
  }
}
.overlay{
  background-color: rgba(254, 254, 254, .8);
}
.sec-menu{
  padding: 99px 17px 0;
  width: 100%;
  height: 100%;
  background-color: #fefefe;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-500px);
  transition: transform .5s;

  .btn-close{
    left: 12px;
    top: 22px;
  }
  &__item{
    @include font-size(24);
    margin-bottom: 48px;
    letter-spacing: 3px;
    transform: translateY(5000px);
    transition: 1s;
    text-transform: uppercase;
    line-height: 1.42;
    //animation: menuItem .5s 1s;
  }

}
.btn-close{
  position: absolute;
  height: 28px;
  width: 32px;
  transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.2555, 1);
  &:before,
  &:after{
    content: '';
    height: 80%;
    width: 1px;
    display: block;
    background-color: color('black', 1);
    @extend .u_overlay;
    margin: auto;
  }
  &:before{
    transform: rotate(45deg);
  }
  &:after{
    transform: rotate(-45deg);
  }
  &:hover{
    transform: rotate(90deg);
  }
}
.warch-cart{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  text-align: center;
  display: none;
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: 0 !important;
  &__active-cart{
    display: none;
  }
  .btn-close{
    top: 24px;
    right: 22px;
  }
  &__wrap{
    display: none;
    max-width: 398px;
    width: 100%;
    background-color: #f8f8f8;
    position: absolute;
    top: 0;
    right: 0;
    padding: 70px 16px 20px;
    min-height: 100%;
    &.active{
      .warch-cart__active-cart{
        display: block;
      }
      .clear-card{
        display: none;
      }
    }
  }
  &__list{
    max-height: 547px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  &__payment{
    padding: 23px 0 19px;
    width: 100%;
    z-index: 1;
    @include font-size(14);
    letter-spacing: 3.5px;
  }
  &__countinue-shop{
    @extend %font-size-12;
    display: inline-block;
    margin: 32px auto 16px;
    position: relative;
    transition: all .3s;
    &:after{
      content: '';
      background-color: color('black', 1);
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      height: 1px;
      transition: all .3s;
    }
    &:hover{
      color: color('red', 1);
      &:after{
        background-color: color('red', 1);
      }
    }
  }
  .count-product__number{
    background-color: #fefefe;
  }
  .mCSB_scrollTools{
    right: -5px !important;
  }
}
.item-cart{
  &__top-field{
    background-color: #fefefe;
    padding: 18px 0 16px;
  }
  &__wrap-img{
    width: 165px;
    margin: 0 auto 7px;
    padding-top: 0;
    display: block;
  }
  &__img{
    width: 100%;
  }
  &__category{
    display: block;
    @include font-size(10);
    letter-spacing: 2.5px;
    text-transform: uppercase;
    margin: 25px 0 0;
  }
  &__title{
    display: block;
    @include font-size(24);
    letter-spacing: 3.5px;
    text-transform: uppercase;
    margin-bottom: 24px;
  }
  &__del{
    opacity: 0.5;
    @include font-size(12);
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-bottom: 24px;
    text-align: center;
    padding: 24px 0;
    width: 100%;
    transition: opacity .3s;
    &:hover{
      opacity: 1;
    }
  }
}
.option{
  text-align: center;
  text-transform: uppercase;
  &__volum{
    @include font-size(16);
  }
  &__gr{
    @include font-size(10);
  }
  &__name{
    @include font-size(8);
    letter-spacing: 1.4px;
    position: relative;
    line-height: 1;
  }
}
.total-cart{
  display: flex;
  justify-content: space-between;
  margin: 10px 0 26px;
  align-items: center;
  &__text{
    @include font-size(10);
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  &__price{
    @extend %font-size-22;
  }
}

.clear-card{
  &__title{
    @include font-size(24);
    letter-spacing: 3px;
    line-height: 1.42;
    text-transform: uppercase;
    border-bottom: 1px solid color('grey', 2);
    padding: 34px 0 40px;
    text-align: center;
  }
  &__text{
    @extend %font-size-14;
    text-align: center;
    padding: 36px 0 60px;
    line-height: 1.58;
    text-transform: none;
  }
  &__btn{
    @include font-size(12);
    width: 100%;
    padding: 22px 0;
    letter-spacing: 5px;
  }
}

@media (min-width: $sm) { // 768
  .menu{
    padding: 14px 40px;
    &__cart{
      &-float{
        display: inline-block;
      }
      &-text{
        @include font-size(12);
        letter-spacing: 3px;
        margin-left: 7px;
      }
    }
  }

  #cart-ic {
    width: 30px;
    padding: 10px 0 0;
  }

  .burger{
    height: 32px;
    width: 32px;
    margin-top: 0;
    span{
      width: 32px;
      &:before,
      &:after{
        width: 32px;
      }
      &:before{
        top: -9px;

      }
      &:after {
        top: 9px;
      }
    }
    &:hover{
      span{
        width: 16px;
        &:after{
          width: 24px;
        }
      }
    }
  }

  .navigation{
    max-width: none;
    position: static;
    &__btn{
      display: none;
    }
    &__list {
      display: flex !important;
      position: static;
      flex-direction: row;
    }
    &__btn{
      @include font-size(12);
      letter-spacing: 3px;
      text-transform: uppercase;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: color('black', 1);
      }
      svg{
        margin-left: 8px;

      }
    }
    &__list{
      padding: 0;
    }
    &__item{
      margin: 0 1px;
      @include font-size(16);
      letter-spacing: 4px;
      line-height: 1.5;
      &:not(:last-child){
        margin-bottom: 0;
      }
    }
    &__link{
      display: block;
      position: relative;
      padding: 10px 20px;
      color: color('grey', 1);

      &:hover,
      &.active{
        background-color: transparent;
        color: color('black', 1);
      }
    }
  }
  .sec-menu {
    padding: 157px 40px 0;
    &__item {
      @include font-size(34);
      margin-bottom: 81px;
      line-height: 1.35;
      letter-spacing: 4px;
    }
    .btn-close{
      left: 40px;
      top: 24px;
    }
  }
  .warch-cart{
    &__wrap{
      padding: 40px;
    }
    &__payment{
      padding: 18px 0 20px;
      letter-spacing: 4px;
      @include font-size(16);
    }
    .btn-close{
      top: 12px;
      right: 12px;
    }
  }
  .item-cart{
    &__wrap-img{
      margin: 0 auto 17px;
      width: 223px;
    }
    &__top-field{
      padding: 22px 0 16px;
    }
    &__category{
      letter-spacing: 3px;
    }
    &__title{
      margin: 3px 0 29px;
      letter-spacing: 4px;
    }
  }
  .total-cart{
    margin: 44px 0 29px;
  }

  .clear-card{
    &__title{
      letter-spacing: 4px;
      line-height: 1.67;
      padding: 72px 0 40px;
    }
    &__btn{
      letter-spacing: 6px;
    }
  }
}
@media (min-width: $md) { //992
  .menu{
    padding: 12px 24px 14px;
    height: 73px;
  }

  .navigation{
    &__item{
      margin: 0 17px;
    }
  }
  .sec-menu{
    max-width: 436px;
    padding: 147px 24px 0;

    .btn-close{
      left: 24px;
      top: 22px;
    }

    &__link{
      position: relative;
      overflow: hidden;
      display: inline-block;
      /*&:after,*/
      &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #343534;
        transform: translate3d(-100%,0,0) translate3d(-1px,0,0);
        transition: transform 0.7s;
        //transition-timing-function: cubic-bezier(0.7,0,0.3,1);
        transition-timing-function: cubic-bezier(0.84, -0.01, 0.48, 0.995);
      }
      /*&:after{
        top: calc(50% - 2px);
        height: 4px;
      }*/
      &:before{
        z-index: 1;
      }
      &:hover:before{
        transform: translate3d(100%,0,0) translate3d(1px,0,0);
      }

      &:hover:after{
        transform: translate3d(0,0,0);
      }
    }

    &__item{
      @include font-size(32);
      margin-bottom: 70px;
      letter-spacing: 0.17em;
    }
  }
  .mCSB_inside>.mCSB_container{
    margin-right: 0;
  }
}
@media (max-height: 800px) {
  .warch-cart__list{
    max-height: none;
  }
}