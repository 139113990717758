.ask-banner{
  height: 166px;
  &__img{
    width: 100%;
  }
}
@media (min-width: $sm){
  .ask-banner{
    height: 260px;
  }
}
@media (min-width: $md){
  .ask-banner{
    height: 399px;
  }
}