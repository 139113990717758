.order{
  &__title{
    @extend %font-size-24;
    margin: 64px 0 16px;
  }
}
.order-total{
  &__item{
    display: flex;
    justify-content: space-between;
    border: 1px solid color('grey', 2);
    border-top: none;
    padding: 24px 14px 21px;
    align-items: center;
    &:first-child{
      background-color: color('black', 1);
      color: #fff;
      padding: 13px 14px 14px;
    }
  }
  &__product,
  &__total{
    width: 50%;
    &-name{
      @extend %font-size-16;
    }
  }
  &__total{
    padding-left: 8px;
  }
  &__title{
    @extend .text-libre;
    margin: 0 0 4px;
    line-height: 1.64;
  }
  &__volume{
    color: color('red', 1);
    @include font-size(12);
    letter-spacing: 3px;
    text-transform: uppercase;
  }
}
//.order-total__total
@media (min-width: $sm){
  .order{
    &__title{
      text-align: center;
      margin: 67px 0 27px;
    }
    &__item{
      padding: 26px 24px 24px;
    }
  }
  .order-total{
    &__item{
      padding: 27px 24px 23px;
      &:first-child{
        padding: 26px 24px 25px;
      }
    }
    &__title{
      margin: 3px 0 11px;
      line-height: 1;
    }
    &__total{
      padding-left: 71px;
    }
  }
}
@media (min-width: $md){
  .order{
    &__title{
      margin: 67px 0 29px;
    }
  }
  .order-total{
    &__total{
      padding-left: 117px;
    }
  }
}