.home-banner{
  padding: 10px 0 31px;
  position: relative;
  &__wrap{
    @extend .u_overlay;
  }
  .wow{
    display: inline-block;
  }
}
.info-banner{
  max-width: 450px;
  &__logo{
    display: inline-flex;
    overflow: hidden;
    svg{
      width: 60px;
      height: 60px;
      margin: 22px 0 13px;
    }
  }
  &__title{
    margin: 3px 0 16px;
    line-height: 1.42;
    overflow: hidden;
    color: #fefefe;
  }
  &__text{
    @extend .text-libre-grey;
    line-height: 1.78;
    overflow: hidden;
    color: #fefefe;
  }
}

@media (min-width: $sm) { // 768
  .home-banner{
    padding: 25px 0 47px;
  }
  .info-banner {
    &__logo {
      svg {
        width: 72px;
        height: auto;
        margin: 0;
      }
    }
    &__title{
      margin: 3px 0 8px;
      line-height: 1.38;
    }
    &__text{
      line-height: 1.89;
    }
  }
}

@media (min-width: $md) { // 992
  .home-banner{
    padding: 72px 0 120px;
  }
  .info-banner{
    max-width: none;
    &__logo svg{
      width: 104px;
    }
    &__title{
      margin: 27px 0 17px;
      line-height: 1.29;
    }
  }
}

