.btn {
  &-black {
    background: color('black', 1);
    @include font-size(10);
    color: #fff;
    letter-spacing: 3px;
    line-height: 1;
    text-transform: uppercase;
    border: 1px solid color('black',1);
    cursor: pointer;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    span{
      position: relative;
      z-index: 1;
    }
    .svg-arrow{
      fill: #fefefe;
      transition: fill .3s;
    }
    &:hover {
      color: color('black', 1);
      background-color: transparent;
      .svg-arrow{
        fill: color('black', 1);
      }
    }
    &_small{
      padding: 21px 0 18px
    }
    &_big{
      padding: 16px 0;
    }
  }
  &-white{
    background: transparent;
    @include font-size(10);
    color: color('black', 1);
    letter-spacing: 3px;
    line-height: 1;
    text-transform: uppercase;
    border: 1px solid color('black',1);
    cursor: pointer;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    outline: none;
    span{
      position: relative;
      z-index: 1;
    }
    &:hover {
      background-color: color('black', 1);
      color: #fff;
    }
    &_small{
      padding: 21px 0 18px
    }
    &_big{
      padding: 16px 0;
    }
  }
  &-red {
    border: 1px solid color('red', 1);
    background-color: transparent;
    @include font-size(14);
    letter-spacing: 3.5px;
    line-height: 1.5;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    &:after{
      content: '';
      display: block;
      width: 0%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      background-color: color('red', 1);
      transition: all .3s;
    }
    &:hover {
      color: #fff;
      &:after{
        width: 100%;
      }
    }
  }
  &-default {
    border: 1px solid color('grey', 2);
    color: color('black', 1);
    font-weight: $weight-semibold;
    text-transform: uppercase;
    //padding: 6px 16px 7px;
    padding: 6px 21px 7px;
    transition: all .3s;
    cursor: pointer;
    &__volume,
    &__gr {
      letter-spacing: 1px;
    }
    &__volume {
      @include font-size(16);
    }
    &__gr {
      @include font-size(10);
    }
    &__name {
      @include font-size(8);
      letter-spacing: 1px;
      position: relative;
      //display: inline;

      &:before{
        content: '';
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 0;
        height: 1px;
        transition: all .3s;
        background-color: color('red', 1);
      }
    }
    &:hover {
      .btn-default__name{
        &:before{
          width: 100%;
        }
      }
    }
    &:active {
      border: 1px solid color('black', 1);
    }
  }
}

.btn-close-small{
  position: absolute;
  height: 12px;
  width: 12px;
  cursor: pointer;
  &:before,
  &:after{
    content: '';
    width: 1px;
    height: 100%;
    background-color: #fefefe;
    @extend .u_overlay;
    margin: auto;
  }
  &:before{
    transform: rotate(45deg);
  }
  &:after{
    transform: rotate(-45deg);
  }
}

.horizontal-line{
  height: 1px;
  width: 40px;
  background-color: #dc3e38;
  display: block;
}

@media screen and (min-width: $sm){
  .btn {
    &-white,
    &-black {
      @include font-size(16);
      letter-spacing: 4px;
      line-height: 1.5;
    }
    &-red{
      @include font-size(16);
      letter-spacing: 4px;
    }
    &-default{
      padding: 14px 0 16px;
      &__name{
        white-space: nowrap;
      }
    }
  }
  .horizontal-line{
    width: 64px;
  }
}



