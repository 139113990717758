a {
	text-decoration: none;
	color: inherit;
	transition: all $transition-time;
	outline: none;
}
h1,
.t_h1,
h2,
.t_h2 {
	@include font-size(24);
	letter-spacing: 3px;
	text-transform: uppercase;
}
h2,
.t_h2{
	line-height: 1.42;
}
h3,
.t_h3,
.price-link{
	@include font-size(20);
	letter-spacing: 3px;
	text-transform: uppercase;
	line-height: 1;
}
h4,
.t_h4 {
	@include font-size(18);

}
h5,
.t_h5 {
	@include font-size(12);
}
h6,
.t_h6 {
	@include font-size(6);
}

%font-size-12{
	@include font-size(12);
	letter-spacing: 3px;
	text-transform: uppercase;
}
%font-size-14{
	@include font-size(14);
	letter-spacing: 4px;
	text-transform: uppercase;
}
%font-size-16{
	@include font-size(14);
	letter-spacing: 3.5px;
	text-transform: uppercase;
}
%font-size-18{
	@include font-size(18);
	letter-spacing: 4px;
	line-height: 1;
	text-transform: uppercase;
}
%font-size-20{
	@include font-size(20);
	letter-spacing: 3px;
	text-transform: uppercase;
}
%font-size-22{
	@include font-size(22);
	letter-spacing: 5px;
	text-transform: uppercase;
}
%font-size-24{
	@include font-size(20);
	letter-spacing: 3px;
	text-transform: uppercase;
}
%font-size-40{
	@include font-size(40);
}

.text-libre{
	@include font-size(14);
	font-family: $libreBaskerville;
	opacity: 0.8;
	font-weight: 400;
}
.text-libre-grey{
	@include font-size(14);
	line-height: 1.71;
	font-family: $libreBaskerville;
	font-weight: 400;
}

%t_color--white { color: white; }
%t_color--black { color: black; }

%t_text-transform--uppercase { text-transform: uppercase; }
%t_text-transform--none { text-transform: none; }
%t_text-align--center { text-align: center; }
%t_text-align--left { text-align: left; }
%t_text-align--right { text-align: right; }

@media (min-width: $sm){ // 768
	h1,
	.t_h1 {
		@include font-size(34);
		letter-spacing: 4px;
		line-height: normal;
	}
	h2,
	.t_h2{
		@include font-size(30);
		letter-spacing: 4px;
	}
	h3,
	.t_h3,
	.price-link {
		@include font-size(24);
	}
	%font-size-16 {
		@include font-size(16);
		letter-spacing: 4px;
	}
	%font-size-24 {
		@include font-size(24);
		letter-spacing: 4px;
	}
	.text-libre-grey,
	.text-libre{
		@include font-size(16);
	}
	.price-link{
		@include font-size(30);
		letter-spacing: 5px;
		line-height: 1.53;
	}
}

@media (min-width: $md){ // 992
	h1,
	.t_h1{
		@include font-size(42);
		letter-spacing: 7px;
		line-height: 1.29;
	}
	h2,
	.t_h2{
		@include font-size(30);
		letter-spacing: .22em;
		line-height: 1.54;
	}

	.text-libre-grey{
		@include font-size(18);
		line-height: 1.88;
	}
	.price-link{
		@include font-size(32);
		line-height: 1.25;
	}
}

