/*
 * enable/disable grid
 */
$bootstrap4: true;

@if $bootstrap4{

  $gutter: 12px;

  $lg: 1200px;
  $md: 992px;
  $sm: 768px;
  $xs: 576px;

  $container-lg: $lg - $gutter * 2;
  $container-md: $md - $gutter * 2;
  $container-sm: $sm - $gutter * 2;
  $container-xs: $xs - $gutter * 2;

  $flex-box: true; // Currently, columns are only with flexbox. Please not enter "false" !
  $pull: false;
  $push: false;
  $offset: false;

  html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  *,
  *::before,
  *::after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }

  .container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 0 16px;
    //padding-left: $gutter;
    max-width: 1240px;
    width: 100%;
  }

  @if $flex-box {

    .row {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;

      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;

      margin-right: -$gutter;
      margin-left: -$gutter;
    }

    .col {
      -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }

    .col-auto {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }

    .col-1 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 8.333333%;
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }

    .col-2 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 16.666667%;
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }

    .col-3 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 25%;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }

    .col-4 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 33.333333%;
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }

    .col-5 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 41.666667%;
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }

    .col-6 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }

    .col-7 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 58.333333%;
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }

    .col-8 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 66.666667%;
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }

    .col-9 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 75%;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }

    .col-10 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 83.333333%;
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }

    .col-11 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 91.666667%;
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }

    .col-12 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }

  } @else {

    //сюда вставить код колонок без флекса

  }

  @media (min-width: $xs) {
    .container {
      max-width: $container-xs;
    }
  }

  @media (min-width: $sm) {
    .container {
      max-width: $container-sm;
      padding: 0 28px;
    }
  }

  @media (min-width: $md) {
    .container {
      max-width: $container-md;
      padding: 0 $gutter;
    }
  }

  @media (min-width: $lg) {
    .container {
      max-width: 1240px;
    }
  }

  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }

  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }

  [class*="col-"]{
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: $gutter;
    padding-left: $gutter;
  }

  @if $pull {

    .pull-0 {
      right: auto;
    }

    .pull-1 {
      right: 8.333333%;
    }

    .pull-2 {
      right: 16.666667%;
    }

    .pull-3 {
      right: 25%;
    }

    .pull-4 {
      right: 33.333333%;
    }

    .pull-5 {
      right: 41.666667%;
    }

    .pull-6 {
      right: 50%;
    }

    .pull-7 {
      right: 58.333333%;
    }

    .pull-8 {
      right: 66.666667%;
    }

    .pull-9 {
      right: 75%;
    }

    .pull-10 {
      right: 83.333333%;
    }

    .pull-11 {
      right: 91.666667%;
    }

    .pull-12 {
      right: 100%;
    }

  }

  @if $push{

    .push-0 {
      left: auto;
    }

    .push-1 {
      left: 8.333333%;
    }

    .push-2 {
      left: 16.666667%;
    }

    .push-3 {
      left: 25%;
    }

    .push-4 {
      left: 33.333333%;
    }

    .push-5 {
      left: 41.666667%;
    }

    .push-6 {
      left: 50%;
    }

    .push-7 {
      left: 58.333333%;
    }

    .push-8 {
      left: 66.666667%;
    }

    .push-9 {
      left: 75%;
    }

    .push-10 {
      left: 83.333333%;
    }

    .push-11 {
      left: 91.666667%;
    }

    .push-12 {
      left: 100%;
    }

  }

  @if $offset{

    .offset-1 {
      margin-left: 8.333333%;
    }

    .offset-2 {
      margin-left: 16.666667%;
    }

    .offset-3 {
      margin-left: 25%;
    }

    .offset-4 {
      margin-left: 33.333333%;
    }

    .offset-5 {
      margin-left: 41.666667%;
    }

    .offset-6 {
      margin-left: 50%;
    }

    .offset-7 {
      margin-left: 58.333333%;
    }

    .offset-8 {
      margin-left: 66.666667%;
    }

    .offset-9 {
      margin-left: 75%;
    }

    .offset-10 {
      margin-left: 83.333333%;
    }

    .offset-11 {
      margin-left: 91.666667%;
    }

  }

  @if $flex-box {

    @media (min-width: $xs) {
      .col-sm {
        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
      }
      .col-sm-auto {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
      }
      .col-sm-1 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.333333%;
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
      }
      .col-sm-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.666667%;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
      }
      .col-sm-3 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
      }
      .col-sm-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.333333%;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }
      .col-sm-5 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.666667%;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
      }
      .col-sm-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
      .col-sm-7 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.333333%;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
      }
      .col-sm-8 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.666667%;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
      }
      .col-sm-9 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
      }
      .col-sm-10 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 83.333333%;
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
      }
      .col-sm-11 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.666667%;
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
      }
      .col-sm-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }

      @if $pull{

      }

      @if $push{

      }

      @if $offset{

      }

      .pull-sm-0 {
        right: auto;
      }
      .pull-sm-1 {
        right: 8.333333%;
      }
      .pull-sm-2 {
        right: 16.666667%;
      }
      .pull-sm-3 {
        right: 25%;
      }
      .pull-sm-4 {
        right: 33.333333%;
      }
      .pull-sm-5 {
        right: 41.666667%;
      }
      .pull-sm-6 {
        right: 50%;
      }
      .pull-sm-7 {
        right: 58.333333%;
      }
      .pull-sm-8 {
        right: 66.666667%;
      }
      .pull-sm-9 {
        right: 75%;
      }
      .pull-sm-10 {
        right: 83.333333%;
      }
      .pull-sm-11 {
        right: 91.666667%;
      }
      .pull-sm-12 {
        right: 100%;
      }
      .push-sm-0 {
        left: auto;
      }
      .push-sm-1 {
        left: 8.333333%;
      }
      .push-sm-2 {
        left: 16.666667%;
      }
      .push-sm-3 {
        left: 25%;
      }
      .push-sm-4 {
        left: 33.333333%;
      }
      .push-sm-5 {
        left: 41.666667%;
      }
      .push-sm-6 {
        left: 50%;
      }
      .push-sm-7 {
        left: 58.333333%;
      }
      .push-sm-8 {
        left: 66.666667%;
      }
      .push-sm-9 {
        left: 75%;
      }
      .push-sm-10 {
        left: 83.333333%;
      }
      .push-sm-11 {
        left: 91.666667%;
      }
      .push-sm-12 {
        left: 100%;
      }
      .offset-sm-0 {
        margin-left: 0%;
      }
      .offset-sm-1 {
        margin-left: 8.333333%;
      }
      .offset-sm-2 {
        margin-left: 16.666667%;
      }
      .offset-sm-3 {
        margin-left: 25%;
      }
      .offset-sm-4 {
        margin-left: 33.333333%;
      }
      .offset-sm-5 {
        margin-left: 41.666667%;
      }
      .offset-sm-6 {
        margin-left: 50%;
      }
      .offset-sm-7 {
        margin-left: 58.333333%;
      }
      .offset-sm-8 {
        margin-left: 66.666667%;
      }
      .offset-sm-9 {
        margin-left: 75%;
      }
      .offset-sm-10 {
        margin-left: 83.333333%;
      }
      .offset-sm-11 {
        margin-left: 91.666667%;
      }
    }

    @media (min-width: $sm) {
      .col-md {
        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
      }
      .col-md-auto {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
      }
      .col-md-1 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.333333%;
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
      }
      .col-md-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.666667%;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
      }
      .col-md-3 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
      }
      .col-md-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.333333%;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }
      .col-md-5 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.666667%;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
      }
      .col-md-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
      .col-md-7 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.333333%;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
      }
      .col-md-8 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.666667%;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
      }
      .col-md-9 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
      }
      .col-md-10 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 83.333333%;
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
      }
      .col-md-11 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.666667%;
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
      }
      .col-md-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }

      @if $pull{

      }

      @if $push{

      }

      @if $offset{

      }
      .pull-md-0 {
        right: auto;
      }
      .pull-md-1 {
        right: 8.333333%;
      }
      .pull-md-2 {
        right: 16.666667%;
      }
      .pull-md-3 {
        right: 25%;
      }
      .pull-md-4 {
        right: 33.333333%;
      }
      .pull-md-5 {
        right: 41.666667%;
      }
      .pull-md-6 {
        right: 50%;
      }
      .pull-md-7 {
        right: 58.333333%;
      }
      .pull-md-8 {
        right: 66.666667%;
      }
      .pull-md-9 {
        right: 75%;
      }
      .pull-md-10 {
        right: 83.333333%;
      }
      .pull-md-11 {
        right: 91.666667%;
      }
      .pull-md-12 {
        right: 100%;
      }
      .push-md-0 {
        left: auto;
      }
      .push-md-1 {
        left: 8.333333%;
      }
      .push-md-2 {
        left: 16.666667%;
      }
      .push-md-3 {
        left: 25%;
      }
      .push-md-4 {
        left: 33.333333%;
      }
      .push-md-5 {
        left: 41.666667%;
      }
      .push-md-6 {
        left: 50%;
      }
      .push-md-7 {
        left: 58.333333%;
      }
      .push-md-8 {
        left: 66.666667%;
      }
      .push-md-9 {
        left: 75%;
      }
      .push-md-10 {
        left: 83.333333%;
      }
      .push-md-11 {
        left: 91.666667%;
      }
      .push-md-12 {
        left: 100%;
      }
      .offset-md-0 {
        margin-left: 0%;
      }
      .offset-md-1 {
        margin-left: 8.333333%;
      }
      .offset-md-2 {
        margin-left: 16.666667%;
      }
      .offset-md-3 {
        margin-left: 25%;
      }
      .offset-md-4 {
        margin-left: 33.333333%;
      }
      .offset-md-5 {
        margin-left: 41.666667%;
      }
      .offset-md-6 {
        margin-left: 50%;
      }
      .offset-md-7 {
        margin-left: 58.333333%;
      }
      .offset-md-8 {
        margin-left: 66.666667%;
      }
      .offset-md-9 {
        margin-left: 75%;
      }
      .offset-md-10 {
        margin-left: 83.333333%;
      }
      .offset-md-11 {
        margin-left: 91.666667%;
      }
    }

    @media (min-width: $md) {
      .col-lg {
        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
      }
      .col-lg-auto {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
      }
      .col-lg-1 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.333333%;
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
      }
      .col-lg-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.666667%;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
      }
      .col-lg-3 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
      }
      .col-lg-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.333333%;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }
      .col-lg-5 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.666667%;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
      }
      .col-lg-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
      .col-lg-7 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.333333%;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
      }
      .col-lg-8 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.666667%;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
      }
      .col-lg-9 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
      }
      .col-lg-10 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 83.333333%;
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
      }
      .col-lg-11 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.666667%;
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
      }
      .col-lg-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }

      @if $pull{

        .pull-lg-0 {
          right: auto;
        }
        .pull-lg-1 {
          right: 8.333333%;
        }
        .pull-lg-2 {
          right: 16.666667%;
        }
        .pull-lg-3 {
          right: 25%;
        }
        .pull-lg-4 {
          right: 33.333333%;
        }
        .pull-lg-5 {
          right: 41.666667%;
        }
        .pull-lg-6 {
          right: 50%;
        }
        .pull-lg-7 {
          right: 58.333333%;
        }
        .pull-lg-8 {
          right: 66.666667%;
        }
        .pull-lg-9 {
          right: 75%;
        }
        .pull-lg-10 {
          right: 83.333333%;
        }
        .pull-lg-11 {
          right: 91.666667%;
        }
        .pull-lg-12 {
          right: 100%;
        }

      }

      @if $push{

        .push-lg-0 {
          left: auto;
        }
        .push-lg-1 {
          left: 8.333333%;
        }
        .push-lg-2 {
          left: 16.666667%;
        }
        .push-lg-3 {
          left: 25%;
        }
        .push-lg-4 {
          left: 33.333333%;
        }
        .push-lg-5 {
          left: 41.666667%;
        }
        .push-lg-6 {
          left: 50%;
        }
        .push-lg-7 {
          left: 58.333333%;
        }
        .push-lg-8 {
          left: 66.666667%;
        }
        .push-lg-9 {
          left: 75%;
        }
        .push-lg-10 {
          left: 83.333333%;
        }
        .push-lg-11 {
          left: 91.666667%;
        }
        .push-lg-12 {
          left: 100%;
        }

      }

      @if $offset{

        .offset-lg-0 {
          margin-left: 0%;
        }
        .offset-lg-1 {
          margin-left: 8.333333%;
        }
        .offset-lg-2 {
          margin-left: 16.666667%;
        }
        .offset-lg-3 {
          margin-left: 25%;
        }
        .offset-lg-4 {
          margin-left: 33.333333%;
        }
        .offset-lg-5 {
          margin-left: 41.666667%;
        }
        .offset-lg-6 {
          margin-left: 50%;
        }
        .offset-lg-7 {
          margin-left: 58.333333%;
        }
        .offset-lg-8 {
          margin-left: 66.666667%;
        }
        .offset-lg-9 {
          margin-left: 75%;
        }
        .offset-lg-10 {
          margin-left: 83.333333%;
        }
        .offset-lg-11 {
          margin-left: 91.666667%;
        }

      }

    }

    @media (min-width: $lg) {
      .col-xl {
        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
      }
      .col-xl-auto {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
      }
      .col-xl-1 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.333333%;
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
      }
      .col-xl-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.666667%;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
      }
      .col-xl-3 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
      }
      .col-xl-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.333333%;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }
      .col-xl-5 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.666667%;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
      }
      .col-xl-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
      .col-xl-7 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.333333%;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
      }
      .col-xl-8 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.666667%;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
      }
      .col-xl-9 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
      }
      .col-xl-10 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 83.333333%;
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
      }
      .col-xl-11 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.666667%;
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
      }
      .col-xl-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }

      @if $pull{

        .pull-xl-0 {
          right: auto;
        }
        .pull-xl-1 {
          right: 8.333333%;
        }
        .pull-xl-2 {
          right: 16.666667%;
        }
        .pull-xl-3 {
          right: 25%;
        }
        .pull-xl-4 {
          right: 33.333333%;
        }
        .pull-xl-5 {
          right: 41.666667%;
        }
        .pull-xl-6 {
          right: 50%;
        }
        .pull-xl-7 {
          right: 58.333333%;
        }
        .pull-xl-8 {
          right: 66.666667%;
        }
        .pull-xl-9 {
          right: 75%;
        }
        .pull-xl-10 {
          right: 83.333333%;
        }
        .pull-xl-11 {
          right: 91.666667%;
        }
        .pull-xl-12 {
          right: 100%;
        }

      }

      @if $push{

        .push-xl-0 {
          left: auto;
        }
        .push-xl-1 {
          left: 8.333333%;
        }
        .push-xl-2 {
          left: 16.666667%;
        }
        .push-xl-3 {
          left: 25%;
        }
        .push-xl-4 {
          left: 33.333333%;
        }
        .push-xl-5 {
          left: 41.666667%;
        }
        .push-xl-6 {
          left: 50%;
        }
        .push-xl-7 {
          left: 58.333333%;
        }
        .push-xl-8 {
          left: 66.666667%;
        }
        .push-xl-9 {
          left: 75%;
        }
        .push-xl-10 {
          left: 83.333333%;
        }
        .push-xl-11 {
          left: 91.666667%;
        }
        .push-xl-12 {
          left: 100%;
        }

      }

      @if $offset{

        .offset-xl-0 {
          margin-left: 0%;
        }
        .offset-xl-1 {
          margin-left: 8.333333%;
        }
        .offset-xl-2 {
          margin-left: 16.666667%;
        }
        .offset-xl-3 {
          margin-left: 25%;
        }
        .offset-xl-4 {
          margin-left: 33.333333%;
        }
        .offset-xl-5 {
          margin-left: 41.666667%;
        }
        .offset-xl-6 {
          margin-left: 50%;
        }
        .offset-xl-7 {
          margin-left: 58.333333%;
        }
        .offset-xl-8 {
          margin-left: 66.666667%;
        }
        .offset-xl-9 {
          margin-left: 75%;
        }
        .offset-xl-10 {
          margin-left: 83.333333%;
        }
        .offset-xl-11 {
          margin-left: 91.666667%;
        }

      }

    }

  } @else {

    //сюда вставить код колонок без флекса

  }
  
}
