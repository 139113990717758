.info-farm{
  padding: 24px 0 64px;
  background-color: #f3f3f3;

  .read{
    margin-top: 29px;
  }

  &__title{
    letter-spacing: 3.5px;
    position: relative;
    margin-bottom: 49px;
    .horizontal-line{
      position: absolute;
      bottom: -25px;
    }
  }
  &__text{
    @extend .text-libre-grey;
    line-height: 1.93;
    max-height: 105px;
    overflow: hidden;
    transition: max-height .3s;
    &.overhidd{
      max-height: 400px;
      transition: max-height .5s;
    }
  }
}
@media (min-width: $sm) { // 768
  .info-farm{
    padding: 24px 0 72px;
    &__title{
      text-align: center;
      line-height: 1.56;
      letter-spacing: 0.15em;
      margin-bottom: 73px;
      .horizontal-line{
        right: 0;
        left: 0;
        margin: auto;
        bottom: -33px;
      }
    }
    &__text{
      column-count: 2;
      column-gap: 40px;
      line-height: 1.89;
      max-height: none !important;
    }
  }
}
@media (min-width: $md){ // 992
  .info-farm{
    padding: 24px 0 104px;
    .container{
      max-width: 842px;
    }
    &__title{
      line-height: 1.68;
      letter-spacing: 0.22em;
      margin-bottom: 75px;
      .horizontal-line{
        bottom: -33px;
      }
    }
  }
}