.shipping{
  &__title{
    @extend %font-size-24;
    margin: 40px 0 16px;
  }
}
.field-options{
  &__wrap{
    max-width: 450px;
  }
  &__group{
    display: flex;
    position: relative;
    padding: 19px 0 22px 48px;
    border: 1px solid color('grey', 2);
    border-top: none;
    &:not(:last-child){
      border-top: 1px solid color('grey', 2);
    }
  }
  &__radio{
    display: none;
    &:checked + .field-options__label:after{
      opacity: 1;
    }
  }
  &__label{
    @extend .u_overlay;
    cursor: pointer;
    z-index: 1;
    &:before,
    &:after{
      content: '';
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      border-radius: 50%;
    }
    &:before{
      height: 16px;
      width: 16px;
      border: 1px solid color('black', 1);
      left: 15px;
    }
    &:after{
      height: 6px;
      width: 6px;
      background-color: color('black', 1);
      opacity: 0;
      transition: opacity .3s;
      left: 20px;
    }
  }
  &__title{
    @extend %font-size-16;
  }
  &__day{
    color: color('red', 1);
    @include font-size(10);
    letter-spacing: 2.5px;
    text-transform: uppercase;
    display: block;
    margin-top: 2px;
  }
  &__text{
    @extend .text-libre;
    margin-top: 1px;
    line-height: 1.64;
    max-width: 160px;
  }
  &__price{
    @include font-size(10);
    letter-spacing: 3.5px;
    margin: 0 14px 0 auto;
    align-self: center;
    text-transform: uppercase;
  }
  &__submit{
    @include font-size(15);
    max-width: 318px;
    width: 100%;
    padding: 20px 0;
    margin: 32px auto 64px;
    display: block;
  }
  &__wrap-img{
    margin: 8px 0 0 0;
    display: flex;
    align-items: center;
  }
  &__img{
    &:not(:first-child){
      margin-left: 10px;
    }
  }
}

@media (min-width: $sm) and (max-width: $md){
  .field-options{
    &__wrap-img{
      margin: 19px 28px auto auto;
      flex-wrap: wrap;
      max-width: 120px;
      justify-content: center;
    }
    &__img{
      margin-bottom: 15px;
    }
    &__label{
      &:after{
        left: 28px;
      }
      &:before{
        left: 23px;
      }
    }
  }
}

@media (min-width: $sm){
  .shipping{
    &__title{
      margin: 43px 0 28px;
      text-align: center;
    }
  }
  .field-options{
    &__group{
      padding: 24px 0 24px 63px;
    }
    &__text{
      line-height: 1.88;
      max-width: 400px;
    }
    &__price{
      @include font-size(16);
      letter-spacing: 4px;
      margin: 0 31px 0 auto;
    }
    &__wrap-img{
      margin: auto 38px auto auto;
    }
    &__day{
      display: inline;
      margin-left: 18px;
      @include font-size(12);
      letter-spacing: 3px;
    }
    &__submit{
      padding: 19px 0;
      margin: 40px auto 72px;
    }
  }

}
@media (min-width: $md){
  .field-options{
    &__wrap{
      max-width: none;
    }
    &__group{
      padding-left: 78px;
    }
    &__label {
      &:before {
        left: 29px
      }
      &:after{
        left: 34px;
      }
    }
    &__wrap-img{

    }
    &__price{
      margin: 0 64px 0 auto;
    }

    &__submit{
      margin: 64px auto 104px;
    }
  }
}