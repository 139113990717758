//-----------------------------------------------------------
// KICKSTART (USED ON EVERY PROJECT)
//-----------------------------------------------------------

@import "001-kickstart/helpers";              // reset + standard helper classes
@import "001-kickstart/mixins";               // common mixins

//-----------------------------------------------------------
// LIBS
//-----------------------------------------------------------

@import "008-libs/slick";                            // slider
@import "008-libs/jquery.mCustomScrollbar";          // jquery.scrollbar
@import "008-libs/animate.css";                      // animate css
@import "008-libs/selectric.scss";                   // selectric scss

//-----------------------------------------------------------
// PROJECT BASE
//-----------------------------------------------------------

@import "002-base/fonts";                     // @font-face declarations
@import "002-base/variables";                 // color, font, breakpoint variables, etc
@import "002-base/base";                      // base styles for project
@import "002-base/layout.scss";               // useful layout styles
@import "002-base/utilities";                 // misc reusables - small chunks as opposed to entire sections like modules
@import "002-base/grid";                      // grid
@import "002-base/typography";                // type styles for project

//-----------------------------------------------------------
// PATTERNS
//-----------------------------------------------------------

@import "003-patterns/sprite";                      // svg sprite to css
@import "003-patterns/buttons";                    // button styles for project
@import "003-patterns/forms";                      // form styles
@import "003-patterns/icons";                 // icon styles
@import "003-patterns/modules";               // larger modules that are reused throughout the site
@import "003-patterns/modals";                // modal styles

//-----------------------------------------------------------
// PAGES
//-----------------------------------------------------------

@import "005-pages/common/_common";                  // common
@import "005-pages/home/home";                       // home
@import "005-pages/product/product";                 // product-cart
@import "005-pages/search/search";                   // search
@import "005-pages/checkout/checkout";               // checkout
@import "005-pages/terms/terms";                     // terms
@import "005-pages/cart/cart";                       // cart

//-----------------------------------------------------------
// THIRD PARTY STYLES
//-----------------------------------------------------------

//@import "006-third-party/_wp.scss";                 // wordpress-specific styles

//-----------------------------------------------------------
// SHAME
//-----------------------------------------------------------

@import "007-shame/shame";                    // styles that need to be refactored, or that are hacky because of browser compatibility etc.

//-----------------------------------------------------------
// GLOBAL ELEMENTS (HEADER & FOOTER, ETC)
//-----------------------------------------------------------

@import "004-globals/header";                      // header styles
@import "004-globals/footer";                      // footer styles
@import "004-globals/menu";                        // menu   styles
@import "004-globals/search";                      // search styles
@import "004-globals/map";                         // map    styles
@import "004-globals/responsive";
@import "004-globals/404";
@import "004-globals/preloader";



