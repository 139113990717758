.product{
  position: static;
  /*&:nth-of-type(2){
    padding-top: 104px;
  }*/
  /*&:nth-of-type(even) {
    .product__item {
      flex-direction: row-reverse;
    }
    .product__desc{
      text-align: right;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }*/

  &:nth-of-type(even) {
    .product__item{
      &:nth-of-type(odd) {
        flex-direction: row-reverse !important;
        .product__sale{
          right: auto;
          left: 0;
        }
        .product__desc{
          text-align: right;
          flex-direction: column;
          align-items: flex-end;
          display: flex;
        }
      }
      &:nth-of-type(even) {
        flex-direction: row !important;

        .product__desc{
          text-align: left;
          flex-direction: column;
          align-items: flex-start;
          display: flex;
          padding-right: 0;
        }
      }
    }
  }
  &:nth-of-type(odd) {
    .product__item{
      &:nth-of-type(even){
        .product__sale{
          right: auto;
          left: 0;
        }
      }
    }
  }
  &-wrap{
    padding: 67px 0 12px;
    .row{
      //position: fixed;
    }
  }
  &__item{
    display: flex;
    margin-bottom: 83px;
    justify-content: space-between;
    &:nth-of-type(even){
      flex-direction: row-reverse;
      .product__desc{
        text-align: right;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
    &:nth-of-type(odd){

    }
  }
  &__desc{
    width: 40%;
    position: relative;
    z-index: 1;
  }
  &__wrap{
    position: relative;
    max-width: 159px;
    width: 100%;
    a{

    }
    &-img{
      width: 100%;
    }
  }
  &__sale{
    min-width: 100px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: color('red', 1);
    color: #fff;
    @include font-size(10);
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 4px;
    padding: 0 5px;
  }
  &__category{
    @include font-size(10);
    display: block;
    letter-spacing: 2.5px;
    line-height: 1.71;
    text-transform: uppercase;
    transition: opacity .3s;
    white-space: nowrap;
    &:hover{
      opacity: .5;
    }
  }
  &__title{
    @include font-size(24);
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 1;
    margin: 2px 0 16px;
    display: inline-block;
    position: relative;
  }
  &__price{
    @include font-size(10);
    letter-spacing: 2.5px;
    color: color('red', 1);
    line-height: 1;
    text-transform: uppercase;
  }
  &__btn{
    margin-top: 24px;
    max-width: 107px;
    width: 100%;
    padding: 15px 0;

  }
}

.loading{
  text-align: center;
  width: 100%;
  position: relative;
  padding: 40px 0;
  margin: 3px 0 49px;
  &__title{
    color: color('black', 1);
    @include font-size(12);
    font-weight: $weight-semibold;
    letter-spacing: 3px;
    line-height: 1.33;
    text-transform: uppercase;
    position: relative;
    display: block;
    z-index: 10;
  }
  &__circle {
    width: 86px;
    height: 86px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
  }

  &__inner-spinner {
    width: 84px;
    height: 84px;
    background: #fefefe;
    border-radius: 50%;
    margin: auto;
    vertical-align: middle;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

  }
  &__spinner {
    height: 0;
    width: 0;
    border-radius: 50%;
    border-right: 43px solid transparent;
    border-top: 43px solid color('red', 1);
    border-left: 43px solid color('red', 1);
    border-bottom: 43px solid color('red', 1);
  }
  &.active{
    .loading__spinner{
      animation: loading 2s infinite linear;
    }
  }

}

@media (min-width: $sm){ //768
  .product{

    &__item{
      margin-bottom: 117px;
    }

    &__title{
      margin: 6px 0 36px;
      @include font-size(32);
      letter-spacing: 5px;
    }

    &__category {
      @include font-size(14);
      letter-spacing: 4px;
    }

    &__desc{
      padding-top: 72px;
    }

    &:nth-of-type(odd){
      .product__item{
        &:nth-of-type(even){
          .product__desc{
            padding-right: 59px;
          }
        }
        &:nth-of-type(odd){
          .product__desc{
            padding-left: 59px;
          }
        }
      }

    }

    &:nth-of-type(even){
      .product__desc{
        padding-right: 59px;
      }
      .product__title{
        white-space: nowrap;
      }
      .product__item{
        &:nth-of-type(even) {
          .product__desc{
            padding-left: 59px;
          }
        }
      }
    }

    &__wrap {
      max-width: 296px;
      padding-top: 40px;
    }

    &__sale{
      min-width: 139px;
      @include font-size(14);
      line-height: 46px;
      padding: 0;
    }

    &-wrap{
      padding: 63px 0 58px;
    }

    &__price {
      @include font-size(14);
      letter-spacing: 4px;
    }

    &__btn{
      max-width: 157px;
      padding: 15px 0;
    }

  }
  .loading{
    &__circle {
      width: 94px;
      height: 94px;
    }
    &__inner-spinner {
      width: 92px;
      height: 92px;
    }
    &__spinner {
      border-right: 47px solid transparent;
      border-top: 47px solid color('red', 1);
      border-left: 47px solid color('red', 1);
      border-bottom: 47px solid color('red', 1);
    }
  }
}
@media (min-width: $md) { // 992
  .product{
    position: relative;

    &-pic{
      &-wrap{
        margin-top: 86px;
      }
    }

    &:nth-of-type(even){
      .product__item:nth-of-type(odd){
        flex-direction: row !important;
      }
    }
    /*&:nth-of-type(even) .product__item:nth-of-type(even),
    &__item:nth-of-type(even){
      flex-direction: row-reverse !important;
    }*/

    &__item {
      padding: 0;
      flex-direction: row !important;
      margin: 0 auto 93px;
      width: 90%;

      &:hover {
        .product__btn {
          opacity: 1;
        }
      }
    }

    /*&__btn{
      opacity: 0;
    }*/

    &-wrap{
      padding: 95px 0 140px;
    }

    &__wrap{
      //margin-right: 40px;
      padding-left: 20px;
      display: flex;
      align-items: center;
    }

    &__sale{
      top: 40px;
      right: 0 !important;
      left: auto !important;
    }

    &__desc{
      padding: 76px 0 0 !important;
      text-align: left !important;
      align-items: flex-start !important;
      min-width: 150px;
      width: calc( 100% - 296px );
    }

    &__title{
      margin: 6px 0 36px;
      white-space: normal !important;
      width: 100%;
    }
  }
}