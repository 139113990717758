.header{
  background: #ffffff;
  &__title{
    @include font-size(10);
    font-weight: $weight-semibold;
    letter-spacing: 2.5px;
    line-height: 1;
    text-align: center;
    padding: 10px 0;
    text-transform: none;
  }
  &__line{
    width: 24px;
    height: 1px;
    background-color: color('black', 1);
    display: inline-block;
    vertical-align: middle;
    margin: 0 16px;
  }
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
  background-color: #e3e3e5 !important;
}
.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail{
  background-color: transparent !important;
}



@media (min-width: $sm) { // 768
  .header{
    &__title{
      padding: 13px 0;
      @include font-size(14);
      letter-spacing: 4.2px;
    }
  }
}