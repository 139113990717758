//-----------------------------------------------------------
// VARIABLES
//-----------------------------------------------------------

//-------------------------------------------------
// Colors

// use with color mixin. 400 is default.

// EXAMPLE:
// background: color('grey', 100);
// color: color('grey');

$colors: (
	'grey': (
		1: #989898, // .t_color--grey-1
		2: #e3e3e5, // .t_color--grey-2
	),

	'red': (
		1: #dc3e38, // .t_color--кув-2
	),
	'black': (
		1: #343534,
		2: #353635
	)
);


$grad-green: linear-gradient(135deg, #0c0 0%, #3c9 100%);
$grad-blue: linear-gradient(135deg, #0099cc 0%, #0066cc 100%);
$grad-red: linear-gradient(113deg, #cc0000, #ff6600);
$grad-gray: linear-gradient(113deg, #999999, #666666);
//-------------------------------------------------
// Fonts

//$HelveticaNeueBold: "HelveticaBold", Helvetica, Arial, sans-serif;
$openSans: 'Open Sans', sans-serif;
$libreBaskerville: 'Libre Baskerville', serif;

$weight-thin: 100;
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;
$weight-black: 900;

//-------------------------------------------------
// Break points


$sm: 320;
$sm-roomy: 375;
$md: 768px;
$lg: 992px;
$xl: 1280;
$xxl: 1400;
$xxxl: 1920;

//-------------------------------------------------
// MISC

$transition-time: 0.3s;