.preloader{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  &-svg{
    animation: loading 2s infinite linear;
  }
}