.search-stores{
  text-align: center;
  padding: 47px 0 40px;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;

  /*&__title{
    letter-spacing: 3px;
  }*/

  &__brief{
    line-height: 1.78;
    @include font-size(14);
    @extend .text-libre;
    max-width: 670px;
    margin: 7px auto;
    padding: 0 20px;
  }
  &__form-field{
    max-width: 260px;
    height: 48px;
    width: 100%;
    margin: 24px auto;
    border: 1px solid #e3e3e5;
    background-color: #fefefe;
    display: flex;
    position: relative;
  }
  &__in-{
    &label{
      width: calc(100% - 48px);
      opacity: 0.5;
      @include font-size(14);
      letter-spacing: 3px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 15px;
      transition: $transition-time;
      &.active{
        padding-bottom: 25px;
        @include font-size(8);
      }
    }
    &text{
      @include font-size(14);
      padding: 14px 14px 0;
      width: calc(100% - 48px);
      border: none;
      outline: none;
      overflow: hidden;
      @include font-size(16);
      color: color('black', 1);
      line-height: 1;
      text-transform: uppercase;
      letter-spacing: 2px;
      height: 100%;
      &:focus{
        &+label{
          padding-bottom: 25px;
          @include font-size(8);
        }
      }

    }
    &sub{
      width: 48px;
      height: 100%;
      border-left: 1px solid #e3e3e5;
      transition: background .3s;
      padding-top: 6px;

      position: relative;
      &:after{
        content: '';
        display: block;
        width: 0%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        transition: all .3s;
        background-color: color('black', 1);
      }
      &:hover {
        &:after{
          width: 100%;
        }
        .search-svg{
          fill: #fefefe;
          stroke: #fefefe;
        }
      }
      .search-svg{
        fill: color('black', 1);
        transition: all .3s;
        stroke: color('black', 1);
        position: relative;
        z-index: 2;
        width: 25px;
        height: 25px;
      }
    }
  }
}

@media (min-width: $sm){
  .search-stores{
    padding: 51px 0 33px;
    &__brief {
      @include font-size(16);
      line-height: 1.9;
      margin: 9px auto;
      padding: 0 50px;
    }
    &__form-field {
      max-width: 368px;
      height: 64px;
    }
    &__in-{
      &text{
        width: calc(100% - 64px);
        padding: 35px 24px 24px;
      }
      &sub{
        width: 64px;
        .search-svg{
          width: 38px;
          height: 38px;
        }
      }
      &label {
        padding-left: 23px;
        width: calc(100% - 64px);
        @include font-size(16);
        letter-spacing: 4px;
        &.active{
          @include font-size(8);
        }
      }
    }
  }
}

@media (min-width: $md){ //992
  .search-stores{
    padding: 48px 0 33px;
    &__brief{
      margin: 12px auto 24px;
      padding: 0 10px;
    }
  }
}