.checkout{
  .container{
    max-width: 1034px;
  }
}
.details{
  padding: 53px 0 0;
  &__title{
    @extend %font-size-24;
    margin: 33px 0 10px;

  }
  &__desc-text{
    @extend .text-libre;
    line-height: 1.68;
    margin: 0 auto 42px;
  }
}

.info-user{
  &.info-second{
    display: none;
  }
  &__group{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 0;
    &:nth-of-type(2){
      margin-bottom: 24px;
    }
  }

  &__field{
    width: 100%;
    position: relative;
    margin-bottom: 24px;

    .selectric{
      border-color: color('grey', 2);
      .label{
        @include font-size(16);
        color: rgba(52, 53, 52, .5);
        background-color: #fefefe;
        padding: 4px 16px;
        height: 48px;
        margin: 0;
        letter-spacing: 3px;
        text-transform: uppercase;
      }
      .button{
        @extend .i-arrow-down;
        color: transparent;
        top: 50%;
        transform: translateY(-50%);
        right: 23px;
        &:after{
          display: none;
        }
      }

    }
    .selectric-items li{
      letter-spacing: 3px;
      color: color('black', 1);
    }

  }

  &__select{
    appearance: none;
  }
  &__select,
  &__inp{
    background-color: #fefefe;
    height: 48px;
    border: 1px solid color('grey', 2);
    text-transform: uppercase;
    width: 100% !important;
    outline: none;
    padding: 12px 16px;
    cursor: pointer;
    transition: all .3s;
    &:focus{
      + .info-user__label{
        @include font-size(8);
        padding-bottom: 25px;
      }
    }
    &.error{
      border: 1px solid color('red', 1);
    }
  }

  &__label{
    @extend %font-size-16;
    @extend .u_overlay;
    opacity: .5;
    display: flex;
    align-items: center;
    padding: 0 16px;
    text-transform: uppercase;
    transition: all .3s;
    cursor: pointer;
    background-color: #fefefe;
    letter-spacing: 3px;
    &.active{
      @include font-size(8);
      padding-bottom: 25px;
    }
  }


  &__area{
    width: 100%;
    height: 78px;
    border: 1px solid #e3e3e5;
    resize: none;
    overflow-y: auto;
    padding: 24px;
    color: color('black', 1) !important;
    &:focus + .info-user__label{
      padding-top: 10px !important;
      opacity: 0;
    }
  }
  .for-area{
    margin: 24px 0 0;
    width: 100%;
    .info-user__label{
      align-items: flex-start;
      padding-top: 15px;

      &.active{
        padding-top: 10px;
        opacity: 0;
      }
    }
  }
}

.checkbox{
  display: flex;
  align-items: center;
  position: relative;
  margin: 34px 0 32px;
  &__different{
    display: inline-block;
    height: 16px;
    width: 16px;
    border: 1px solid #343534;
    margin: 0 16px 0 0;
    outline: none;
    cursor: pointer;
    &:checked + .checkbox__text:before{
      opacity: 1;
    }
  }
  &__text{
    @extend %font-size-16;
    cursor: pointer;
    width: calc( 100% - 32px );
    line-height: 1;
    &:before{
      content: '';
      @extend .i-checked;
      background-color: color('black', 1);
      position: absolute;
      top: 5px;
      left: 0;
      opacity: 0;
      transition: all .3s;
      height: 16px;
      width: 16px;
      border-bottom: 4px solid transparent;
      border-left: 2px solid transparent;
    }
  }
}

@media (min-width: $sm) and (max-width: $md){
  .checkout{
    .container{
      padding: 0 40px;
    }
    &__text{
      &:before{
        top: 3px;
      }
    }
  }
}
@media (min-width: $sm){
  .details{
    padding: 40px 0 0;
    &__title{
      text-align: center;
      margin: 43px 0 12px;
    }
    &__main-title{
      text-align: center;
    }
    &__desc-text{
      text-align: center;
      max-width: 80%;
      line-height: 1.88;
      margin-bottom: 40px;
    }
  }
  .info-user{
    &__field{
      width: calc( 50% - 12px);
      margin-bottom: 0;
      .selectric{
        .label{
          padding: 10px 24px;
          height: 62px;
          letter-spacing: 4px;
        }
      }
    }
    &__group{
      margin-bottom: 24px;
      &:nth-of-type(2){
        margin-bottom: 48px;
      }
    }
    &__label{
      letter-spacing: 4px;
      padding: 0 24px;
    }
    &__area{
      height: 138px;
    }
    .for-area{
      .info-user__label{
        padding-top: 21px;
      }
    }
    /*.for-area{
      margin-top: 24px;
    }*/
    &__select,
    &__inp{
      height: 64px;
      padding: 20px 24px;
    }
  }
  .checkbox{
    margin: 12px 0 33px;
    &__text{
      line-height: normal;
      &:before{
        top: 3px;
      }
    }
  }
}
@media (min-width: $md){
  .details{
    padding: 80px 0 0;
    &__desc-text{
      margin: 0 auto 32px;
    }
  }
}
