@keyframes pulse {
  0%{
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-15px);
  }
  100%{
    transform: translateY(0px);
  }
}

@keyframes jump{
  0%{
    transform: translate3d(0px,0px,0);
    animation-timing-function: ease-out;
  }
  10%{
    transform: translate3d(0px,-20px,0);
    animation-timing-function: ease-in;
  }
  20%{
    transform: translate3d(0px,0px,0);
    animation-timing-function: ease-out;
  }
  30%{
    transform: translate3d(0px,-20px,0);
    animation-timing-function: ease-in;
  }
  40%{
    transform: translate3d(0px,0px,0);
    animation-timing-function: ease-out;
  }
  50%{
    transform: translate3d(0px,-20px,0);
    animation-timing-function: ease-in;
  }
  60%{
    transform: translate3d(0px,0px,0);
    animation-timing-function: ease-out;
  }
  100%{
    transform: translate3d(0px,0px,0);
    animation-timing-function: ease-out;
  }
}


#markerLayer {
  &>div{
    overflow: visible !important;
  }
  img {
    //animation: pulse .5s linear;
    transform-origin: center;
    transform: translateY(0px);
    transition: all .5s cubic-bezier(0.84, -0.01, 0.48, 0.995);
  }
}



.contacts-map{
  padding: 55px 14px 64px;
}

.contact-desc{
  display: flex;
  flex-direction: column;
  .horizontal-line{
    margin: -8px 0 28px 48px;
  }
  &__title{
    margin-bottom: 24px;
    width: 100%;
  }
  &__address{
    @extend .text-libre;
    max-width: 200px;
  }
  &__mail,
  &__address,
  &__phone{
    @extend .text-libre;
    padding-left: 48px;
    box-sizing: content-box;
    position: relative;
    display: block;
    line-height: 1.72;
    margin-bottom: 32px;
    margin-right: 29px;

  }
  &__mail,
  &__address{
    .icon-svg{
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &__phone{
    .icon-svg{
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
  &__mail{
    text-decoration: underline;
    transition: color .3s;
    &:hover{
      color: color('red', 1);
    }
  }
}

.gm-style .gm-style-iw{
  overflow: visible;
}
.gm-style-iw{
  opacity: 0;
  transition: .3s;
  //width: 245px;
  min-width: 245px;
  //max-width: 245px;
}

#map{
  //max-width: 802px;
  width: 100%;
  //height: 458px;
  height: 100%;
  display: block;
  //transform: translateX(-100%);
  //transition: 1s cubic-bezier(1,.07,1,.92);
}

.wrap-map{
  max-width: 802px;
  height: 215px;
  width: 100%;
  margin-top: 21px;
  overflow: hidden;
}

.marker-info{
  min-width: 245px;
  background-color: rgba(254, 254, 254, .9);
  padding: 16px;
  width: 245px;
  position: relative;
  &:after{
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: rgba(254, 254, 254, .9);
    bottom: -10px;
    left: 15px;
    transform: rotate(45deg);
  }
  &__title{
    @include font-size(14);
    font-weight: 600;
    color: color('black', 1);
    font-family: $openSans;
    letter-spacing: 4px;
    text-transform: uppercase;
    padding: 15px 0 15px;
    border-bottom: 1px solid #e3e3e5;
  }
  &__address{
    opacity: 0.8;
    color: color('black', 1);
    font-family: $libreBaskerville;
    font-weight: $weight-regular;
    padding: 20px 0 20px;
    @include font-size(12);
  }
  &-close{
    position: relative;
    &:after,
    &:before{
      content: '';
      display: block;
      position: absolute;
      background: #404240;
      top: 5px;
      width: 13px;
      height: 1px;
    }
    &:before{
      right: 0;
      transform: rotate(-45deg);
    }
    &:after{
      left: 0;
      transform: rotate(45deg);
    }
  }
}

@media (min-width: $sm) { // 768
  .marker-info{
    width: 311px;
    min-width: 311px;
    &__title{
      @include font-size(18);
    }
    &__address{
      @include font-size(16);
    }
    &-close{
      &:after,
      &:before{
        top: 6px;
        width: 13px;
        height: 1px;
      }
      &:before{
        right: 0;
        transform: rotate(-45deg);
      }
      &:after{
        left: 0;
        transform: rotate(45deg);
      }
    }
  }

  .wrap-map{
    margin-top: 0;
    height: 300px;
  }

  .contacts-map{
    padding: 70px 24px 64px;
  }

  .contact-desc{
    flex-direction: row;
    flex-wrap: wrap;

    .horizontal-line{
      position: absolute;
      top: 68px;
      left: 0;
      right: 0;
      margin: auto;
    }

    &__title{
      text-align: center;
      margin-bottom: 52px;
    }

    &__mail,
    &__address,
    &__phone{
      line-height: 1.88;
      margin-bottom: 40px;
    }
    &__phone{
      .icon-svg{
        top: 0;
        transform: none;
      }
    }
  }
}
@media (min-width: $md) { // 992
  .burger{
    margin-top: 0;
  }
  .contacts-map{
    padding: 104px 0;

    .container{
      max-width: 1010px;
    }

    .row{
      justify-content: flex-end;
    }
  }

  .contact-desc {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    padding-top: 64px;
    display: block;
    .horizontal-line{
      position: static;
      margin: -2px 0 24px 46px;
    }
    &__title{
      width: auto;
      text-align: left;
      margin-bottom: 16px;
    }
    &__mail,
    &__address,
    &__phone{
      margin-right: 0;
      margin-bottom: 26px;
    }
  }

  .wrap-map{
    height: 458px;
  }
}